export const ecoTitle = "hypergpt ecosystem";

export const ecoDesc = {
    first: "Join us and become an integral part of this unique ecosystem,",
    second: "where endless opportunities await.",
};

export const ecoCardItems = [{
        id: "01",
        title: "$HGPT Token",
        desc: "$HGPT token is a versatile utility token that drives HyperGPT platform adoption and growth.",
        buttonText: "Buy Now",
        link: "",
    },
    {
        id: "02",
        title: "Staking Program",
        desc: "Staking $HGPT incentivizes users to support & secure the platform while earning rewards",
        buttonText: "Stake Now",
        link: "https://staking.hypergpt.ai/",
    },
    {
        id: "03",
        title: "DAO Voting",
        desc: "$HGPT empowers holders to shape the ecosystem through a DAO, enabling them to propose, vote on, and allocate funds.",
        buttonText: "Vote Now",
        link: "https://snapshot.org/#/hypergptdao.eth ",
    },
];