import React, { useEffect, useState } from 'react';
import './TextFieldPrice.css';

const TextFieldPrice = ({ title, placeholder, isReadOnly, value, onChange, type, unit = "", max = 2 }) => {

  const [valueInput, setValueInput] = useState(value);
  const [isFocused, setIsFocused] = useState(false);


  useEffect(() => {
    setValueInput(value)
  }, [value]);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleOnChange = (e) => {

    const inputValue = e.target.value;
    // Chỉ cho phép nhập số và dấu chấm
    const isValid = /^[0-9.]*$/.test(inputValue);

    if (isValid) {
      const onlyNumbers = inputValue.replace(/[^0-9.]/g, '');

      // Kiểm tra xem có nhiều hơn một dấu chấm thập phân không
      const decimalCount = (onlyNumbers.match(/\./g) || []).length;
      // Giới hạn tối đa 2 chữ số thập phân
      const decimalPart = onlyNumbers.split('.')[1];
      if (decimalCount <= 1 && (!decimalPart || decimalPart.length <= max)) {
        setValueInput(onlyNumbers);
        onChange(onlyNumbers)
      }
    }

  }
  const handleBlur = () => {
    setIsFocused(false);
  };

  const handleWheel = (event) => {
    event.target.blur();
  }

  return (
    <div className={`inputAmount ${isFocused ? 'input_focus' : ''}`} >
      <label style={{color: isReadOnly ? '#808080' : 'white'}}>{title}</label>
      <div className='content-unit'>
        <input
          type={type}
          className='input'
          placeholder={placeholder}
          value={valueInput}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={handleOnChange}
          autoFocus={isFocused}
          width='100%'
          readOnly={isReadOnly}
          onWheel={handleWheel}
          style={{color: isReadOnly ? '#808080' : 'white'}}
        />
        <p className='p-unit'>{unit}</p>
      </div>
    </div>
  );
};

export default TextFieldPrice;
