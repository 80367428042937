// components/Layout/Layout.js

import React from "react";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import styles from "../../../css/components/Layout/Layout.module.scss";
import { useLocation } from "react-router-dom";
import Login from "../Login/Login";
import { useState } from 'react'
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"

const Layout = ({ children }) => {
  const location = useLocation();
  const isHome = location.pathname === "/";
  const isAbout = location.pathname === "/about";
  const isAmbassador = location.pathname === "/ambassador-program";
  const [showPopup, setShowPopup] = useState(false);
  const [isLoginSuccess, setIsLoginSuccess] = useState(false)
  const [isLogined, setIsLogined] = useState(false)
  const notShowHeader = location.pathname === "/term-service" || location.pathname === "/privacy-policy" || location.pathname === "/risk-warning"

  const toggleShowPopup = () => {
    setShowPopup(!showPopup)
  };

  const dismissLogin = () => {
    setIsLogined(true)
    console.log('ilodin', isLogined)
    setIsLoginSuccess(true)
    setShowPopup(false)
    toast.success("Login successfully", {
      position: 'bottom-right'
    })
    setIsLoginSuccess(false)
  }

  const closePopup = () => {
    setShowPopup(false)
  }

  return (
    <div className={styles.layout}>

      {!notShowHeader && <Navbar onLoginClick={toggleShowPopup} isLogined = {isLogined}/>}
      
      {/* show popup login */}
      <Popup
        trigger={null}
        closeOnEscape={false}
        modal
        nested
        open={showPopup}
        onClose={closePopup}
        overlayStyle={{
          background: 'rgba(0, 0, 0, 0.5)', // Màu overlay
          zIndex: 10000, // Đảm bảo overlay nằm trên màn hình A
          position: 'fixed',
          pointerEvents: "none",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,

        }} 
        lockScroll={true}
      >
        <Login dismissLogin={dismissLogin} />
        {showPopup && !isLoginSuccess && (<ToastContainer autoClose={5000} />)}
      </Popup>

      <main className={styles.mainContent}> {children}</main>
      <Footer />
    </div>
  );
};

export default Layout;
