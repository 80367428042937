import {
    makeApiRequest,
    generateSymbol,
    parseFullSymbol,
    apiKey,
} from './helpers.js';
import {
    subscribeOnStream,
    unsubscribeFromStream,
} from './streaming.js';
import eventEmitter from '../../common/eventEmitter/eventEmitter.js';
const lastBarsCache = new Map();


// DatafeedConfiguration implementation
const configurationData = {
    // Represents the resolutions for bars supported by your datafeed
    supported_resolutions: ["1", "5", "15", "60", "240", "D", "2D", "3D", "W", "3W", "M", "6M"],
    supports_search: true,
    supports_group_request: false,
    supports_marks: true,
    supports_timescale_marks: true,
    supports_time: true,


    // The `exchanges` arguments are used for the `searchSymbols` method if a user selects the exchange
    exchanges: [
        // {
        //  value: 'Bitfinex',
        //  name: 'Bitfinex',
        //  desc: 'Bitfinex',
        // },
        // {
        //  value: 'Kraken',
        //  // Filter name
        //  name: 'Kraken',
        //  // Full exchange name displayed in the filter popup
        //  desc: 'Kraken bitcoin exchange',
        // },
        // { value: 'bitget', name: 'bitget', desc: 'bitget' },
        { value: 'Binance', name: 'Binance', desc: 'Binance' }
    ],

    // exchanges: [
    //     { value: "", name: "All Exchanges", desc: "" },
    //     { value: "NasdaqNM", name: "NasdaqNM", desc: "NasdaqNM" },
    //     { value: "NYSE", name: "NYSE", desc: "NYSE" }
    // ],
    symbols_types: [
        { name: "All types", value: "" },
        { name: "Stock", value: "stock" },
        { name: "Index", value: "index" }
    ],

    // The `symbols_types` arguments are used for the `searchSymbols` method if a user selects this symbol type
    // symbols_types: [{
    //  name: 'crypto',
    //  value: 'crypto',
    // },
    // ],
};

// Obtains all symbols for all exchanges supported by CryptoCompare API
async function getAllSymbols() {
    const data = await makeApiRequest('data/v3/all/exchanges');
    let allSymbols = [];

    for (const exchange of configurationData.exchanges) {
        const pairs = data.Data[exchange.value].pairs;

        for (const leftPairPart of Object.keys(pairs)) {
            const symbols = pairs[leftPairPart].map(rightPairPart => {
                const symbol = generateSymbol(exchange.value, leftPairPart, rightPairPart);
                return {
                    symbol: symbol.short,
                    full_name: symbol.full,
                    description: symbol.short,
                    exchange: exchange.value,
                    type: 'crypto',
                };
            });
            allSymbols = [...allSymbols, ...symbols];
        }
    }
    return allSymbols;
}

export default {
    onReady: (callback) => {
        console.log('[onReady]: Method call');
        setTimeout(() => callback(configurationData));
    },

    searchSymbols: async (
        userInput,
        exchange,
        symbolType,
        onResultReadyCallback,
    ) => {
        console.log('[searchSymbols]: Method call');
        const symbols = await getAllSymbols();
        window.SYMBOLS = symbols
        const newSymbols = symbols.filter(symbol => {
            const isExchangeValid = exchange === '' || symbol.exchange === exchange;
            const isFullSymbolContainsInput = symbol.full_name
                .toLowerCase()
                .indexOf(userInput.toLowerCase()) !== -1;
            return isExchangeValid && isFullSymbolContainsInput;
        });
        onResultReadyCallback(newSymbols);
    },

    resolveSymbol: async (
        symbolName,
        onSymbolResolvedCallback,
        onResolveErrorCallback,
        extension
    ) => {
        console.log('[resolveSymbol]: Method call', symbolName);
        const symbols = await getAllSymbols();
        window.SYMBOLS = symbols
        const symbolItem = symbols.find(({
            full_name,
        }) => full_name === symbolName);
        if (!symbolItem) {
            console.log('[resolveSymbol]: Cannot resolve symbol', symbolName);
            onResolveErrorCallback('cannot resolve symbol');
            return;
        }
        // Symbol information object
        const symbolInfo = {
            ticker: symbolItem.full_name,
            name: symbolItem.symbol,
            description: symbolItem.description,
            type: symbolItem.type,
            session: '24x7',
            timezone: 'Etc/UTC',
            exchange: symbolItem.exchange,
            minmov: 1,
            pricescale: 100,
            has_intraday: true,
            has_no_volume: true,
            has_weekly_and_monthly: false,
            supported_resolutions: configurationData.supported_resolutions,
            volume_precision: 2,
            data_status: 'streaming',
        };
        onSymbolResolvedCallback(symbolInfo);
    },

    getBars: async (symbolInfo, resolution, periodParams, onHistoryCallback, onErrorCallback) => {
        const { from, to, firstDataRequest } = periodParams;
        console.log('[getBars]: Method call', symbolInfo, resolution, from, to);
        // Phát sự kiện khi symbolInfo thay đổi
        eventEmitter.emit('symbolInfoChanged', symbolInfo.name);
        const parsedSymbol = parseFullSymbol(symbolInfo.full_name);
        const urlParameters = {
            e: parsedSymbol.exchange,
            fsym: parsedSymbol.fromSymbol,
            tsym: parsedSymbol.toSymbol,
            toTs: to,
            limit: 2000,
        };
        const query = Object.keys(urlParameters)
            .map(name => `${name}=${encodeURIComponent(urlParameters[name])}`)
            .join('&');
        try {
            let data = "";
			let res = "";

			const endpoints = {
				'1': 'histominute',
				'5': 'histominute',
				'15': 'histominute',
				'60': 'histohour',
				'240': 'histohour'
			};

			const endpoint = endpoints[resolution] || 'histoday';
			const apiUrl = endpoint === 'histoday' 
				? `data/histoday?${query}` 
				: `data/v2/${endpoint}?fsym=${urlParameters.fsym}&tsym=${urlParameters.tsym}&limit=${urlParameters.limit}`;

			res = await makeApiRequest(apiUrl);
			data = endpoint === 'histoday' ? res.Data : res.Data.Data.slice(0, -1);
            if (res.Response && res.Response === 'Error' || data.length === 0) {
                // "noData" should be set if there is no data in the requested period
                onHistoryCallback([], {
                    noData: true,
                });
                return;
            }
            let bars = [];

            data.forEach(bar => {
                if (bar.time >= from && bar.time < to) {
                    bars = [...bars, {
                        time: bar.time * 1000,
                        low: bar.low,
                        high: bar.high,
                        open: bar.open,
                        close: bar.close,
                    }];
                }
            });
            if (firstDataRequest) {
                lastBarsCache.set(symbolInfo.full_name, {
                    ...bars[bars.length - 1],
                });
            }
            console.log(`[getBars]: returned ${bars.length} bar(s)`);
            onHistoryCallback(bars, {
                noData: false,
            });
        } catch (error) {
            console.log('[getBars]: Get error', error);
            onErrorCallback(error);
        }
    },

    subscribeBars: (
        symbolInfo,
        resolution,
        onRealtimeCallback,
        subscriberUID,
        onResetCacheNeededCallback,
    ) => {
        console.log('[subscribeBars]: Method call with subscriberUID:', subscriberUID);
        subscribeOnStream(
            symbolInfo,
            resolution,
            onRealtimeCallback,
            subscriberUID,
            onResetCacheNeededCallback,
            lastBarsCache.get(symbolInfo.full_name),
        );
    },

    unsubscribeBars: (subscriberUID) => {
        console.log('[unsubscribeBars]: Method call with subscriberUID:', subscriberUID);
        unsubscribeFromStream(subscriberUID);
    },
};
