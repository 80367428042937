import styles from './BuyMarketView.module.scss'
import { useState, useEffect, useDebugValue } from 'react';
import SliderPrice from '../../components/Chart/SliderPrice';
import Button from '../../components/Button/Button';
import TextFieldPrice from '../TextField/TextFieldPrice';
import { useSelector } from 'react-redux';
import service from '../../../service/Service';
import { formatNumber } from '../../common/NumberFormat';
import { toast } from "react-toastify";
import { removeAfterLastSlash, roundDown, roundUp } from '../../common/Common';

const BuyMarketView = () => {

  const [amount, setAmount] = useState(0)
  const [percentValue, setPercentValue] = useState(0)
  const [total, setTotal] = useState(0)
  const nameSymbol = useSelector((state) => state.trade.nameSymbol)
  const [titleButton, setTitleButton] = useState(`Buy ${nameSymbol}`)
  const priceSymbol = useSelector((state) => state.trade.priceSymbol);
  const [price, setPrice] = useState(priceSymbol)
  const avlb = useSelector((state) => state.trade.avlbUSDT)

  useEffect(() => {
    setTitleButton(`Buy ${nameSymbol}`)
    resetAllValue()
  }, [nameSymbol]);

  useEffect(() => {
    setPrice(formatNumber(priceSymbol))
  }, [priceSymbol]);

  const resetAllValue = () => {
    setPercentValue(0)
    setTotal(0)
  }

  const getAmount = (data) => {
    setAmount(data)
    let totalTemp = parseFloat(priceSymbol) * parseFloat(data)
    setTotal(totalTemp)
  }

  const getPercentValue = (data) => {
    setPercentValue(data)
    setTotal(roundDown(data * avlb / 100))
  }

  const getTotal = (data) => {
    setTotal(data)
    setPercentValue(0)
  }

  const handleConfirm = async () => {
     
    if (roundDown(total / priceSymbol) < 0.01) {
      toast.warning(`Quantity ${removeAfterLastSlash(nameSymbol)} of must be at least 0.01`, {
        position: 'bottom-right'
      })
    } else {
      let value = {
        coinName: nameSymbol,
        quantity: roundDown(total / priceSymbol),
        currentPrice: parseFloat(priceSymbol),
        totalPay: roundUp(total),
        type: "buy",
      }
  
      try {
        let res = await service.addMarket(value)
        toast.success(`Buy ${nameSymbol} successfully`, {
          position: 'bottom-right'
        })
      } catch (error) {
        toast.error(`${error}`, {
          position: 'bottom-right'
        })
      }
    }
  }

  return (
    <div className={styles.container}>
      <TextFieldPrice title='Market Price' placeholder='Enter' isReadOnly={true} value={formatNumber(priceSymbol)} type="text" />
      <TextFieldPrice title='Total' placeholder='Enter' value={total} onChange={getTotal} type="text" unit='USDT' />
      <p style={{ color: 'white', textAlign: 'center', marginTop: '-20px', fontSize: '14px' }} > ≈ {roundDown(total / priceSymbol)} { removeAfterLastSlash(nameSymbol)}</p>
      <SliderPrice value={percentValue} handleSliderChange={getPercentValue} />

      <div className={styles.availablePrice}>
        <p>Avlb</p>
        <p>{avlb} USDT</p>
      </div>

      <div style={{ marginTop: '30px' }}>
        <Button
          isDisabled={(total < 0 || total == 0 || total > avlb || total === '')}
          onClick={() => {
            handleConfirm()
          }}
          title={titleButton}
          size="large"
          isGradient={true}
          key={1}
          style={{ width: '100%' }}
        />
      </div>
    </div>
  );
}

export default BuyMarketView;