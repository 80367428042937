import styles from './StackingView.module.scss'
import { useEffect, useState } from 'react';
import SelectOptions, { ChooseTime, CustomOption } from '../../components/SelectOptions/SelectOptions';
import TextFieldPrice from '../TextField/TextFieldPrice';
import Button from '../../components/Button/Button';
import service from '../../../service/Service';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { removeAfterLastSlash, roundDown } from '../../common/Common';

const StackingView = () => {

    const [timeOptions, setTimeOptions] = useState([
        { value: 1, label: '1 month', profit: '5.25' },
        { value: 3, label: '3 months', profit: '5.25' },
        { value: 6, label: '6 months', profit: '5.25' },
        { value: 12, label: '1 year', profit: '5.25' },
        { value: 24, label: '2 years', profit: '5.25' },
    ])

    const [selectTime, setSelectTime] = useState(null)
    const [amount, setAmount] = useState('')
    const nameSymbol = useSelector((state) => removeAfterLastSlash(state.trade.nameSymbol))
    const [showStacking, setShowStacking] = useState(false)
    const [avlbQuantity, setAvlbQuantity] = useState(0)
    const [loading, setLoading] = useState(true)
    const [profit, setProfit] = useState()
    const arrowString = "  --->  "

    useEffect(() => {
        setLoading(true)
        getWalletByCoin()
        getRate()

    }, [nameSymbol])

    useEffect(() => {
        const profit = (selectTime === null || amount == 0) ? 0 :  roundDown((parseFloat(amount) * parseInt(selectTime.profit) / 100 + parseFloat(amount)), 7)
        setProfit(profit)
    }, [selectTime, amount])

    const getWalletByCoin = async () => {
        try {
            let res = await service.getWalletByCoin(removeAfterLastSlash(nameSymbol))
            setAvlbQuantity(res.data.data[0].quantity)
            setShowStacking(true)
            setLoading(false)
        } catch (err) {
            setShowStacking(false)
            setLoading(false)
        }
    }

    const getRate = async () => {
        try {
            let res = await service.getStackingRate()
            adjustTimeOptions(res.data.data.rate)

        } catch (err) {
            setShowStacking(false)
            setLoading(false)
        }
    }

    const adjustTimeOptions = (originalData) => {
        setTimeOptions(
            Object.entries(originalData).map(([key, value]) => ({
                value: parseInt(key, 10),
                label: key === '1' ? `${key} month` : `${key} months`,
                profit: value
            }))
        )
    }

    const handleSelectTime = (time) => {
        setSelectTime(time)
    }

    const handleGetAmount = (data) => {
        setAmount(data)
    }

    const confirmStacking = async () => {
        if (selectTime == null || amount == '') {
            toast.warning(`Please fill out all the fields`, {
                position: 'bottom-right'
            })
        }
        else {
            const value = {
                coinName: nameSymbol,
                monthSaving: selectTime.value,
                quantity: parseFloat(amount)
            }

            try {
                let res = await service.stacking(value)
                toast.success(`${res.data.data}`, {
                    position: 'bottom-right'
                })
            }
            catch (error) {
                console.log('catch', error)
                toast.error(`${error}`, {
                    position: 'bottom-right'
                })
            }
        }
    }

    const stackingView = () => {
        return (
            <>
                <div className={styles.inputView}>
                    <p>The coin name</p>
                    <TextFieldPrice title="Coin name" value={nameSymbol} isReadOnly={true} />
                </div>
                <div className={styles.inputView}>
                    <p>Choose a term </p>
                    <SelectOptions
                        value={selectTime}
                        onSelect={handleSelectTime}
                        options={timeOptions}
                        view={ChooseTime}
                    />
                </div>
                <div className={styles.inputView}>
                    <p>Input Amount</p>
                    <TextFieldPrice title="Amount" onChange={handleGetAmount} type="number" unit={removeAfterLastSlash(nameSymbol)} />
                </div>
                <div className={styles.availablePrice}>
                    <p>Avlb</p>
                    <p>{avlbQuantity} {removeAfterLastSlash(nameSymbol)}</p>
                </div>

                <div className={styles.profit}>
                    <p >Profit</p>
                    <p> {profit}</p>
                </div>

                <div className={styles.buttonStart}>
                    <Button
                        onClick={() => {
                            confirmStacking()
                        }}
                        isDisabled={amount > avlbQuantity || amount == 0 || selectTime === null}
                        title="Confirm"
                        size="large"
                        isGradient={true}
                        key={1}
                        style={{ width: '100%' }}
                    />
                </div>
            </>
        )
    }

    const nonStackingView = () => {
        return (
            <div className={styles.nonStacking}>
                <p>Deposit {removeAfterLastSlash(nameSymbol)} to continue using this function </p>
                <p> Go to <b>Profile</b> {arrowString} <b>Deposit</b></p>
            </div>
        )
    }

    return (
        <div className={styles.container}>
            {loading ? <></> : <>{showStacking ? stackingView() : nonStackingView()}</>}

        </div>
    );
}

export default StackingView;
