import { useContext, useCallback } from "react";
// import { BaseLogo } from "../../assets";
import BaseLogo from "../../../js/assets/images/logos/BaseLogo.svg"
import logo from "../../../logo.svg"
import { footerBottomItems, footerSocial } from "../../utils/constants/Footer";
import Button from "../Button/Button";
import { NotificationContext } from "../../context/NotificationContext";
import styles from "../../../css/components/Footer/Footer.module.scss"
import React  from 'react';

import axios from "axios";
import { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";

const Footer = () => {
  const { dispatch } = useContext(NotificationContext);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [inquiry, setInquiry] = useState("");
  const [isVerified, setIsVerified] = useState(false);

  const instance = axios.create({
    baseURL: "https://api.hypergpt.ai",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET, POST",
      "Access-Control-Allow-Headers":
        "Content-Type, Authorization, X-Requested-With",
    },
  });

  const handleRecaptchaChange = (value) => {
    if (value) {
      setIsVerified(true);
    }
  };

  const handleSubmit = async () => {
    if (!isVerified) {
      dispatch({
        type: "SET_NOTIFICATION",
        payload: {
          message: "Please verify you are a human.",
          type: "danger",
        },
      });
      return;
    }

    if (!name || !email || !inquiry || !message) {
      dispatch({
        type: "SET_NOTIFICATION",
        payload: {
          message: "Please fill in all fields.",
          type: "danger",
        },
      });
    } else {
      try {
        const params = new URLSearchParams({
          message,
          subject: inquiry,
          email,
          username: name,
        });
        await instance.post(`/user/sendAdminMessage?${params.toString()}`);
        dispatch({
          type: "SET_NOTIFICATION",
          payload: {
            message: "The message has been successfully sent.",
            type: "success",
          },
        });
        setName("");
        setEmail("");
        setMessage("");
        setInquiry("");
      } catch (error) {
        console.error("Error sending message:", error);
      }
    }
  };

  return (
    <footer className={styles.footer}>
      <div className={styles.footer_bottom}>
        <div className={styles.footer_bottom__first}>
          <img src={BaseLogo} alt="" />
          <p>
            <strong>Decentralogic Limited</strong>
            <br />
            <a
              href="https://www.google.com/maps/search/?api=1&query=Intershore+Chambers,+PO+Box+4342,+Road+Town,+Tortola,+VG1110,+BV"
              target="_blank"
              rel="noopener noreferrer"
            >
              Intershore Chambers, PO Box 4342, Road Town, <br /> Tortola,
              VG1110, BV
            </a>
          </p>
        </div>

        {footerBottomItems.map((item, index) => {
          return (
            <div className={styles.footer_bottom__second}>
              <span>{item.title}</span>
              {item.items.map((item, index) => {
                return <a href={item.link}>{item.name}</a>;
              })}
            </div>
          );
        })}
      </div>
    </footer>
  );
};

export default Footer;
