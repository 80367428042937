import React, { useState, useEffect } from 'react';
import './FormProfile.css';
import service from '../../../service/Service';
import { toast } from "react-toastify";
import Button from '../../components/Button/Button';

const FormProfile = () => {

    const [imageUrl, setImageUrl] = useState('https://via.placeholder.com/100'); // Replace with your initial image URL
    const [formData, setFormData] = useState({
        avatar: '',
        fullName: '',
        phoneNumber: '',
        birthDay: '',
        email: '',
        address: '',
        country: ''
    });
    const [originalFormData, setOriginalFormData] = useState({});

    useEffect(() => {
        getUserMe()
    }, []);

    const getUserMe = async () => {
        try {
            let res = await service.userMe()
            let data = res.data.data
            const profileData = data.profile || {
                avatar: '',
                fullName: '',
                phoneNumber: '',
                birthDay: '',
                address: '',
                country: ''
            };
            const newFormData = {
                avatar: profileData.avatar,
                fullName: profileData.fullname,
                phoneNumber: profileData.phone,
                birthDay: formatDateToYYYYMMDD(profileData.birthDay),
                email: data.email,
                address: profileData.address,
                country: profileData.country
            };

            setImageUrl(newFormData.avatar);
            setFormData(newFormData);
            setOriginalFormData(newFormData);
        } catch (error) {
            toast.error(`${error}`, {
                position: 'bottom-right'
            })
        }
    }

    const updateProfile = async () => {
        try {
            let res = await service.updateProfile(formData, imageUrl)
            toast.success("Update successfully", {
                position: 'bottom-right'
            })
        } catch (error) {
            toast.error(`${error}`, {
                position: 'bottom-right'
            })
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target;

        // thieues avatar
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        const formData = new FormData();
        formData.append('file', file);
        try {
            let res = await service.uploadImage(formData)
            console.log('res', res.data.data.path)
            setImageUrl(res.data.data.path)
        } catch (error) {
            toast.error(`${error}`, {
                position: 'bottom-right'
            })
        }
    };

    const resetEdit = () => {
        setFormData(originalFormData);
    };

    const formatDateToYYYYMMDD = (dateString) => {
        if (!dateString) return '';
        return new Date(dateString).toISOString().split('T')[0];
    };

    const handleSubmit = (e) => {
        e.preventDefault();
    };

    return (

        <div className="profile-info">
            {/* <img src="https://via.placeholder.com/100" alt="Avatar" className="avatar" /> */}
            <div className="image-wrapper">
                <img src={imageUrl} alt="Avatar" className="avatar" />
                <label className="overlay" htmlFor="fileInput">
                    <input
                        className="overlay"
                        id="fileInput"
                        type="file"
                        accept="image/*"
                        style={{ display: 'none' }}
                        onChange={handleFileChange}
                    />
                </label>
            </div>

            <form className="profile-form" onSubmit={handleSubmit}>
                <div className="form-group">
                    <label>Full name</label>
                    <input
                        type="text"
                        name="fullName"
                        value={formData.fullName}
                        onChange={handleChange}
                    />
                </div>
                <div className="form-group">
                    <label>Phone Number</label>
                    <input
                        type="text"
                        name="phoneNumber"
                        value={formData.phoneNumber}
                        onChange={handleChange}
                    />
                </div>
                <div className="form-group">
                    <label>Email</label>
                    <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        readOnly
                    />
                </div>
                <div className="form-group">
                    <label>Birthday</label>
                    <input
                        type="date"
                        name="birthDay"
                        value={formData.birthDay}
                        onChange={handleChange}
                    />
                </div>
                <div className="form-group">
                    <label>Address</label>
                    <input
                        type="text"
                        name="address"
                        value={formData.address}
                        onChange={handleChange}
                    />
                </div>
                <div className="form-group">
                    <label>Country</label>
                    <input
                        type="text"
                        name="country"
                        value={formData.country}
                        onChange={handleChange}
                    />
                </div>
                <div className="form-actions">
                    <button type="button" className="cancel-btn" onClick={() => resetEdit()}>CANCEL</button>
                    <Button
                        onClick={updateProfile}
                        size='small'
                        title='Save'
                        isGradient={true}
                        style={{ padding: '12px 25px' }}
                    />
                </div>
            </form>

        </div>

    );
};

export default FormProfile;
