import { useEffect, useState } from 'react';
import './Dashboard.scss';
import service from '../../../service/Service';
import { toast } from 'react-toastify';

const Dashboard = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const [users, setUsers] = useState([]);
    const [limit, setLimit] = useState(5);
    const [total, setTotal] = useState(0);
    const [allUsers, setAllUsers] = useState([]);
    const [searchContent, setSearchContent] = useState('');
    const [allUsersFetched, setAllUsersFetched] = useState(false);
    const [notificationContent, setNotificationContent] = useState({})

    const getUsersPerPage = async () => {
        try {
            const response = await service.getAllUsers(limit, currentPage);
            const data = response.data.data;
            const totalUsers = response.data.pagination.total;

            setUsers(data);
            setTotal(totalUsers);
        } catch (error) {
            console.error("Failed to fetch users for the current page:", error);
        }
    };

    const getAllUsers = async () => {
        try {
            const response = await service.getAllUsers(Number.MAX_SAFE_INTEGER, 1);
            setAllUsers(response.data.data);
            setAllUsersFetched(true);
        } catch (error) {
            console.error("Failed to fetch all users:", error);
        }
    };

    const searchAndSetUsers = () => {
        if (searchContent.trim() !== '') {
            const searchResult = allUsers.filter(user =>
                user.email.toLowerCase().includes(searchContent.toLowerCase())
            );

            const startIndex = (currentPage - 1) * limit;
            const paginatedResult = searchResult.slice(startIndex, startIndex + limit);

            setUsers(paginatedResult);
            setTotal(searchResult.length);
        } else {
            getUsersPerPage();
        }
    };

    useEffect(() => {
        if (searchContent.trim() === '') {
            getUsersPerPage();
        } else {
            if (!allUsersFetched) {
                getAllUsers();
            } else {
                searchAndSetUsers();
            }
        }
    }, [currentPage, limit, searchContent, allUsersFetched]);

    useEffect(() => {
        if (searchContent.trim() !== '' && !allUsersFetched) {
            getAllUsers();
        }
    }, [searchContent, allUsersFetched]);

    const handleChangePage = (page) => {
        setCurrentPage(page);
    };

    const handleSearch = (e) => {
        setSearchContent(e.target.value);
        setCurrentPage(1);
    };

    const handleNotification = (userId, e) => {
        setNotificationContent({
            ...notificationContent,
            [userId]: e.target.value
        });
    }

    const sendNotification = async (content, userId) => {
        try {
            console.log(`DAY LA: ${content}`);
            await service.sendOneNotification(content, userId.toString());
            toast.success(`Notification "${notificationContent[userId]}" has been send!`)
        } catch (error) {
            toast.error(`Some errors were happenned!`)
        }
    }

    return (
        <div className="dashboard">
            <div className='dashboard-search'>
                <input className='dashboard-search-input' type='text' placeholder='Search' value={searchContent} onChange={handleSearch} />
            </div>
            <table>
                <thead>
                    <tr>
                        <th className='email'>Email</th>
                        <th>Avatar</th>
                        <th>Notification</th>
                        <th className='edit'>Account status</th>
                        <th className='delete'>Delete account</th>
                    </tr>
                </thead>
                <tbody>
                    {users.map((value, index) => (
                        <tr key={index}>
                            <td>{value.email}</td>
                            <td><img src={value.profile != null ? value.profile.avatar : ''} className='user-avatar' alt="avatar" /></td>
                            <td className='td-textarea'>
                                <textarea className='notification' onChange={(e) => handleNotification(value.id, e)} 
                                value={notificationContent[value.id] || ''} placeholder='Notification'></textarea>
                                <button onClick={() => sendNotification(notificationContent[value.id], value.id)}>Send</button>
                            </td>
                            <td><button>Active</button></td>
                            <td><button>Delete</button></td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <div className="pagination">
                {Array.from({ length: Math.ceil(total / limit) }, (_, index) => (
                    <button
                        key={index}
                        onClick={() => handleChangePage(index + 1)}
                        className={currentPage === index + 1 ? 'active' : ''}
                    >
                        {index + 1}
                    </button>
                ))}
            </div>
        </div>
    );
};

export default Dashboard;
