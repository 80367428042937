import styles from './BuyLimitView.module.scss'
import { useState, useEffect } from 'react';
import SliderPrice from '../../components/Chart/SliderPrice';
import TextFieldPrice from '../TextField/TextFieldPrice';
import Button from '../../components/Button/Button';
import { useSelector } from 'react-redux';
import service from '../../../service/Service';
import { formatNumber } from '../../common/NumberFormat';
import { toast } from "react-toastify";

const BuyStopLimitView = ({ value }) => {

  const [amount, setAmount] = useState(0)
  const [percentValue, setPercentValue] = useState(0)
  const [total, setTotal] = useState('0')
  const nameSymbol = useSelector((state) => state.trade.nameSymbol)
  const [titleButton, setTitleButton] = useState(`Buy ${nameSymbol}`)
  const priceSymbol = useSelector((state) => state.trade.priceSymbol);
  const [price, setPrice] = useState(priceSymbol)
  const [avlb, setAvlb] = useState(10)

  useEffect(() => {
    setTitleButton(`Buy ${nameSymbol}`)
  }, [nameSymbol]);

  useEffect(() => {
    setPrice(formatNumber(priceSymbol))
  }, [priceSymbol]);

  const getAmount = (data) => {
    setAmount(data)
    let totalTemp = parseFloat(priceSymbol) * parseFloat(data)
    setTotal(totalTemp)
  }

  const getPercentValue = (data) => {
    setPercentValue(data)
    setTotal(data * avlb / 100)
  }

  const getTotal = (data) => {
    setTotal(data)
    setPercentValue(0)
  }

  const handleConfirm = async () => {
    let value = {
      coinName: nameSymbol,
      quantity: 1,
      currentPrice: parseFloat(priceSymbol),
      totalPay: parseInt(total),
      type: "buy",
    }

    try {
      let res = await service.addMarket(value)
      toast.success(`Buy ${nameSymbol} successfully`, {
        position: 'bottom-right'
      })
    } catch (error) {
      toast.error(`${error}`, {
        position: 'bottom-right'
      })
    }
  }


  return (
    <div className={styles.container}>
      <TextFieldPrice title='Stop (USTD)' placeholder='Enter' type="number"/>
      <TextFieldPrice title={`${value} (USTD)`} placeholder='Enter' type="number"/>
      <TextFieldPrice title='Amount' placeholder='Enter' type="number"/>
      <SliderPrice handleSliderChange={getPercentValue} />
      <TextFieldPrice title='Total' placeholder='Enter' type="number"/>
      <div style={{ marginTop: '30px' }}>
        <Button
          onClick={() => {
            handleConfirm()
          }}
          title="Confirm"
          size="large"
          isGradient={true}
          key={1}
          style={{ width: '100%' }}
        />
      </div>
    </div>
  );
}

export default BuyStopLimitView;