export const priceSymbol = (newData) => {
    console.log('new data ', newData)
    return {
        type: 'PriceSYMBOL',
        payload: newData
    }
}

export const nameSymbol = (newData) => {
    return {
        type: 'NameSYMBOL',
        payload: newData
    }
}

export const avlbUSDT = (newData) => {
    return {
        type: 'AvailableUSDT',
        payload: newData
    }
}