// import {
//     Telegram,
//     Discord,
//     Twitter,
//     Youtube
// } from "../../assets";
// import logo from "../../../logo.svg"
import Telegram from '../../../js/assets/images/icons/Telegram.svg'

export const footerTop = {
    titleFirst: "Let's get in",
    titleSecond: "touch today!",
    descFirst: "Please use this form to get in touch with",
    descSecond: "us. We would be happy to assist you",
    descThird: "with any inquiries or feedback you may",
    descFourth: "have.",
    mail: "hello@hypergpt.ai",
};

export const footerSocial = [{
        name: "Telegram",
        link: "https://t.me/HyperGPTai",
        icon: Telegram,
    },
    {
        name: "Telegram",
        link: " https://t.me/hypergpt_news ",
        icon: Telegram,
    },
    {
        name: "Discord",
        link: "https://discord.com/invite/hypergpt ",
        icon: Telegram,
    },
    {
        name: "Twitter",
        link: "https://twitter.com/hypergpt ",
        icon: Telegram,
    },
];

export const footerBottomItems = [{
        title: "Company",
        items: [{
                name: "About us",
                link: "/about",
            },
            {
                name: "Press Kit",
                link: "/#",
            },
            {
                name: "Careers",
                link: "hello@hypergpt.ai",
            },
            {
                name: "Pricing",
                link: "/",
            },
        ],
    },
    {
        title: "Resources",
        items: [{
                name: "Docs",
                link: "https://docs.hypergpt.ai/hypergpt/",
            },
            {
                name: "Github",
                link: "https://github.com/HyperGPTai",
            },
            {
                name: "Roadmap",
                link: "https://docs.hypergpt.ai/hypergpt/roadmap",
            },
        ],
    },
    {
        title: "$HGPT",
        items: [{
                name: "Stake Now",
                link: "https://staking.hypergpt.ai/",
            },
            {
                name: "Claim Now",
                link: "https://claim.hypergpt.ai/user/dashboard/0x529C79f6918665EBE250F32eeEAA1d410a0798C6?smartContractAddress=0x6712Ea814554E3182199387f65154874B33670B3",
            },
            {
                name: "Coinmarketcap",
                link: "https://coinmarketcap.com/currencies/hypergpt/",
            },
            {
                name: "Coingecko",
                link: "https://www.coingecko.com/en/coins/hypergpt",
            },
        ],
    },
];