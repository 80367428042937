
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import Rhombus from './../../assets/images/icons/Rhombus.png'
import { useEffect, useState } from 'react';

const SliderPrice = ({ value, handleSliderChange, unit = "%" }) => {

    const marks = [
        { value: 0 },
        { value: 25 },
        { value: 50 },
        { value: 75 },
        { value: 100 },
        { value: 125 },
    ];

    const [valueText, setValueText] = useState(value)

    useEffect(() => {
        setValueText(value)
        setFormatValue(value)
    }, [value]);

    const handleSliderOnChange = (event, newValue) => {
        handleSliderChange(newValue)
    };

    const setFormatValue = (value) => {
        return `${value}${unit}`
    }

    return (
        <Box sx={{ width: '98%' }}>
            <Slider
                aria-label="Temperature"
                defaultValue={0}
                value={valueText}
                valueLabelFormat={setFormatValue}
                valueLabelDisplay="on"
                step={1}
                marks={marks}
                onChange={handleSliderOnChange}
                max={unit === "%" ? 100: 125}
                min={unit === "%" ? 0: 1}
                sx={{
                    '& .MuiSlider-thumb': {
                        '&:hover, &.Mui-focusVisible': {
                            boxShadow: 'none',
                        },
                        width: '20px',
                        height: '20px',
                        background: 'transparent',
                        backgroundImage: `url(${Rhombus})`, // Sử dụng hình ảnh thumb tùy chỉnh
                        backgroundSize: 'contain',
                        '&:active': {
                            boxShadow: 'none',
                        },
                    },
                    '& .MuiSlider-rail': {
                        backgroundColor: '#4a4a4a', // Màu của phần chưa kéo của Slider
                    },
                    '& .MuiSlider-track': {
                        backgroundColor: 'white', // Default color for track
                    },
                    '& .MuiSlider-mark': {
                        backgroundColor: '#4a4a4a', // Màu của cục mark
                        width: '8px', // Độ rộng của cục mark
                        height: '8px', // Độ cao của cục mark
                        borderRadius: '50%', // Hình dạng của cục mark (làm cho nó tròn
                    },
                }}
            />
        </Box>

    )

}

export default SliderPrice