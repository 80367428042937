import { useEffect, useState } from 'react'
import './DropdownInput.scss'

const DropdownInput = ({ placeholder, defaultValue, options, onDropdownChange, value, onInputChange, max = 2 }) => {

    const [valueInput, setValueInput] = useState(value)

    useEffect(() => {
        setValueInput(value)
    }, [value]);

    const handleWheel = (event) => {
        event.target.blur();
    }

    const handleOnChange = (e) => {

        const inputValue = e.target.value;
        // Chỉ cho phép nhập số và dấu chấm
        const isValid = /^[0-9.]*$/.test(inputValue);

        if (isValid) {
            const onlyNumbers = inputValue.replace(/[^0-9.]/g, '');

            // Kiểm tra xem có nhiều hơn một dấu chấm thập phân không
            const decimalCount = (onlyNumbers.match(/\./g) || []).length;
            // Giới hạn tối đa 2 chữ số thập phân
            const decimalPart = onlyNumbers.split('.')[1];
            if (decimalCount <= 1 && (!decimalPart || decimalPart.length <= max)) {
                setValueInput(onlyNumbers)
                onInputChange(onlyNumbers)
            }
        }
    }

    return (
        <div className='dropdown-input'>
            <div className="currency">
                <label>{placeholder}</label>
                <input placeholder="Enter" value={valueInput} onChange={handleOnChange} type='text' onWheel={handleWheel}></input>
            </div>
            <select defaultValue={defaultValue} onChange={onDropdownChange}>
                {options.map((option, index) => (
                    <option key={index} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </select>
        </div>
    )
}

export default DropdownInput
