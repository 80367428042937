import { parseFullSymbol, apiKey } from './helpers.js';
import { removeAfterLastSlash, removeBeforeLastSlash, removeCenterSlash } from '../../common/Common.js';
import eventEmitter from '../../common/eventEmitter/eventEmitter.js';

let nameSymbol = 'btcusdt'
let firstName = 'btc'
let lastName = 'usdt'
let socket1
const exchange = 'Binance'
let currentSymbol = null;

const handleSymbolInfoChange = (data) => {

	if (removeAfterLastSlash(data) !== firstName || removeBeforeLastSlash(data) !== lastName) {
		socket1.send(JSON.stringify({
            method: "UNSUBSCRIBE",
            params: [`${currentSymbol}@trade`],
            id: 1
        }));
        console.log(`Unsubscribed from ${currentSymbol}`);
	}

	nameSymbol = removeCenterSlash(data)
	firstName = removeAfterLastSlash(data)
	lastName = removeBeforeLastSlash(data)

	const newSymbol = removeCenterSlash(data);

    // Gửi lệnh unsubscribe cho symbol cũ nếu có
    if (currentSymbol) {
        socket1.send(JSON.stringify({
            method: "UNSUBSCRIBE",
            params: [`${currentSymbol}@trade`],
            id: 1
        }));
        console.log(`Unsubscribed from ${currentSymbol}`);
    }

    // Gửi lệnh subscribe cho symbol mới
    socket1.send(JSON.stringify({
        method: "SUBSCRIBE",
        params: [`${newSymbol}@trade`],
        id: 2
    }));
    console.log(`Subscribed to ${newSymbol}`);
	 // Cập nhật currentSymbol thành newSymbol
	 currentSymbol = newSymbol;
}

socket1 = new WebSocket(`wss://stream.binance.com:9443/ws/${nameSymbol}@trade`);

// Event listener when the connection is open
socket1.onopen = () => {
    console.log('===> WebSocket connection established.');

	eventEmitter.on('symbolInfoChanged', handleSymbolInfoChange);
};

socket1.onmessage = (event) => {
    let data = JSON.parse(event.data);
    console.log('===> Message from server:', data);

	console.log('[socket] Message:', data);

	if(Object.keys(data).length == 2) {
		return
	}
	
	const {
		E: tradeTimeStr,
		p: tradePriceStr,
	} = data;

	console.log("DCM:" + JSON.stringify(data));
	
	// if (parseInt(eventTypeStr) !== 0) {
	// 	// Skip all non-trading events
	// 	return;
	// }
	const tradePrice = parseFloat(tradePriceStr);
	const tradeTime = parseInt(tradeTimeStr);
	const channelString = `0~${exchange}~${firstName}~${lastName}`;
	const subscriptionItem = channelToSubscription.get(channelString);
	if (subscriptionItem === undefined) {
		return;
	}
	const lastDailyBar = subscriptionItem.lastDailyBar;
	const nextDailyBarTime = getNextDailyBarTime(lastDailyBar.time);

	let bar;
	if (tradeTime >= nextDailyBarTime) {
		bar = {
			time: nextDailyBarTime,
			open: tradePrice,
			high: tradePrice,
			low: tradePrice,
			close: tradePrice,
		};
		console.log('[socket] Generate new bar', bar);
	} else {
		bar = {
			...lastDailyBar,
			high: Math.max(lastDailyBar.high, tradePrice),
			low: Math.min(lastDailyBar.low, tradePrice),
			close: tradePrice,
		};
		console.log('[socket] Update the latest bar by price', tradePrice);
	}
	subscriptionItem.lastDailyBar = bar;

	// Send data to every subscriber of that symbol
	subscriptionItem.handlers.forEach((handler) => handler.callback(bar));
};

// Event listener for errors
socket1.onerror = (error) => {
    console.error('===> WebSocket error:', error);
};

// Event listener when the connection is closed
socket1.onclose = () => {
    console.log('===> WebSocket connection closed.');
};

const channelToSubscription = new Map();

// socket.addEventListener('open', () => {
// 	console.log('[socket] Connected');
// });

// socket.addEventListener('close', (reason) => {
// 	console.log('[socket] Disconnected:', reason);
// });

// socket.addEventListener('error', (error) => {
// 	console.log('[socket] Error:', error);
// });

// socket.addEventListener('message', (event) => {
// 	const data = JSON.parse(event.data);
// 	console.log('[socket] Message:', data);
// 	const {
// 		TYPE: eventTypeStr,
// 		M: exchange,
// 		FSYM: fromSymbol,
// 		TSYM: toSymbol,
// 		TS: tradeTimeStr,
// 		P: tradePriceStr,
// 	} = data;

// 	console.log("DCM:" + JSON.stringify(data));
	

// 	if (parseInt(eventTypeStr) !== 0) {
// 		// Skip all non-trading events
// 		return;
// 	}
// 	const tradePrice = parseFloat(tradePriceStr);
// 	const tradeTime = parseInt(tradeTimeStr);
// 	const channelString = `0~${exchange}~${fromSymbol}~${toSymbol}`;
// 	const subscriptionItem = channelToSubscription.get(channelString);
// 	if (subscriptionItem === undefined) {
// 		return;
// 	}
// 	const lastDailyBar = subscriptionItem.lastDailyBar;
// 	const nextDailyBarTime = getNextDailyBarTime(lastDailyBar.time);

// 	let bar;
// 	if (tradeTime >= nextDailyBarTime) {
// 		bar = {
// 			time: nextDailyBarTime,
// 			open: tradePrice,
// 			high: tradePrice,
// 			low: tradePrice,
// 			close: tradePrice,
// 		};
// 		console.log('[socket] Generate new bar', bar);
// 	} else {
// 		bar = {
// 			...lastDailyBar,
// 			high: Math.max(lastDailyBar.high, tradePrice),
// 			low: Math.min(lastDailyBar.low, tradePrice),
// 			close: tradePrice,
// 		};
// 		console.log('[socket] Update the latest bar by price', tradePrice);
// 	}
// 	subscriptionItem.lastDailyBar = bar;

// 	// Send data to every subscriber of that symbol
// 	subscriptionItem.handlers.forEach((handler) => handler.callback(bar));
// });

function getNextDailyBarTime(barTime) {
	const date = new Date(barTime * 1000);
	date.setDate(date.getDate() + 1);
	return date.getTime() / 1000;
}

export function subscribeOnStream(
	symbolInfo,
	resolution,
	onRealtimeCallback,
	subscriberUID,
	onResetCacheNeededCallback,
	lastDailyBar
) {
	// const parsedSymbol = parseFullSymbol(symbolInfo.full_name);
	const channelString = `0~${exchange}~${firstName}~${lastName}`;
	const handler = {
		id: subscriberUID,
		callback: onRealtimeCallback,
	};
	let subscriptionItem = channelToSubscription.get(channelString);
	if (subscriptionItem) {
		// Already subscribed to the channel, use the existing subscription
		subscriptionItem.handlers.push(handler);
		return;
	}
	subscriptionItem = {
		subscriberUID,
		resolution,
		lastDailyBar,
		handlers: [handler],
	};
	channelToSubscription.set(channelString, subscriptionItem);
	console.log(
		'[subscribeBars]: Subscribe to streaming. Channel:',
		channelString
	);
	const subRequest = {
		action: 'SubAdd',
		subs: [channelString],
	};
	// socket.send(JSON.stringify(subRequest));
}

export function unsubscribeFromStream(subscriberUID) {
	// Find a subscription with id === subscriberUID
	for (const channelString of channelToSubscription.keys()) {
		const subscriptionItem = channelToSubscription.get(channelString);
		const handlerIndex = subscriptionItem.handlers.findIndex(
			(handler) => handler.id === subscriberUID
		);

		if (handlerIndex !== -1) {
			// Remove from handlers
			subscriptionItem.handlers.splice(handlerIndex, 1);

			if (subscriptionItem.handlers.length === 0) {
				// Unsubscribe from the channel if it was the last handler
				console.log(
					'[unsubscribeBars]: Unsubscribe from streaming. Channel:',
					channelString
				);
				const subRequest = {
					action: 'SubRemove',
					subs: [channelString],
				};
				// socket.send(JSON.stringify(subRequest));
				channelToSubscription.delete(channelString);
				break;
			}
		}
	}
}


// const name = 'btcusdt'; // Example: trading pair for Bitcoin/USDT
// const socket1 = new WebSocket(wss://stream.binance.com:9443/ws/${name}@trade);

// // Event listener when the connection is open
// socket1.onopen = () => {
//     console.log('===> WebSocket connection established.');

//     // Send a message to the server (if required by the API)
//     const message = {
//         method: 'SUBSCRIBE',
//         params: [`${name}@trade`],
//         id: 1
//     };

//     socket1.send(JSON.stringify(message));
// };

// // Event listener for incoming messages
// socket1.onmessage = (event) => {
//     const data = JSON.parse(event.data);
//     console.log('===> Message from server:', data);
// };

// // Event listener for errors
// socket1.onerror = (error) => {
//     console.error('===> WebSocket error:', error);
// };

// // Event listener when the connection is closed
// socket1.onclose = () => {
//     console.log('===> WebSocket connection closed.');
// };