import styles from './SellMarketView.module.scss'
import { useState, useEffect } from 'react';
import SliderPrice from '../../components/Chart/SliderPrice';
import Button from '../../components/Button/Button';
import TextFieldPrice from '../TextField/TextFieldPrice';
import { useSelector } from 'react-redux';
import service from '../../../service/Service';
import { formatNumber } from '../../common/NumberFormat';
import { toast } from "react-toastify";
import { removeAfterLastSlash, roundDown } from '../../common/Common';

const SellMarketView = () => {

  const [amount, setAmount] = useState(0)
  const [percentValue, setPercentValue] = useState(0)
  const [total, setTotal] = useState(0)
  const nameSymbol = useSelector((state) => state.trade.nameSymbol)
  const [titleButton, setTitleButton] = useState(`Sell ${nameSymbol}`)
  const priceSymbol = useSelector((state) => state.trade.priceSymbol);
  const [price, setPrice] = useState(priceSymbol)
  const [avlb, setAvlb] = useState(10)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setTitleButton(`Sell ${nameSymbol}`)
    getWalletByCoin()
  }, [nameSymbol]);

  useEffect(() => {
    setPrice(formatNumber(priceSymbol))
  }, [priceSymbol]);

  const getWalletByCoin = async () => {
    try {
      let res = await service.getWalletByCoin(removeAfterLastSlash(nameSymbol))
      setAvlb(res.data.data[0].quantity)
      setLoading(false)
    } catch (err) {
      setLoading(true)
    }
  }

  const getAmount = (data) => {
    setAmount(data)
    let totalTemp = parseFloat(priceSymbol) * parseFloat(data)
    setTotal(totalTemp)
  }

  const getPercentValue = (data) => {
    setPercentValue(data)
    setTotal(roundDown(data * avlb / 100))
  }

  const getTotal = (data) => {
    setTotal(data)
    setPercentValue(0)
  }

  const handleConfirm = async () => {

    if (total == 0 || total === '') {
      toast.warning(`Please fill out all the fields`, {
        position: 'bottom-right'
      })
    } else {

      let value = {
        coinName: nameSymbol,
        quantity: parseFloat(total),
        currentPrice: parseFloat(priceSymbol),
        totalPay: parseFloat(total *   priceSymbol),
        type: "sell",
      }

      try {
        // bán market
        let res = await service.addMarket(value)
        toast.success(`Sell ${nameSymbol} successfully`, {
          position: 'bottom-right'
        })
      } catch (error) {
        toast.error(`${error}`, {
          position: 'bottom-right'
        })
      }
    }
  }

  const mainView = () => {
    return (
      <><TextFieldPrice title='Market Price' placeholder='Enter' isReadOnly={true} value={formatNumber(priceSymbol)} type="text" />
        <TextFieldPrice title='Total' placeholder='Enter' value={total} onChange={getTotal} type="text" unit={removeAfterLastSlash(nameSymbol)}/>
        <SliderPrice value={percentValue} handleSliderChange={getPercentValue} />
        <div className={styles.availablePrice}>
          <p>Avlb</p>
          <p>{avlb} {removeAfterLastSlash(nameSymbol)}</p>
        </div>
        <div style={{ marginTop: '30px' }}>
          <Button
            onClick={() => {
              handleConfirm()
            }}
            isDisabled={total <= 0 || total > avlb || total === ''}
            title={titleButton}
            size="large"
            isGradient={true}
            key={1}
            style={{ width: '100%' }}
          />
        </div>
      </>
    )
  }

  const nonSell = () => {
    return (
      <p style={{color: 'white'}}>No market coin to sell</p>
    )
  }

  return (
    <div className={styles.container}>
      {loading ? nonSell() : mainView()}
    </div>
  );
}

export default SellMarketView;