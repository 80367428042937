import React from "react";
import PagesLayout from "../../components/Layout/PagesLayout";
import {
    nftContent
} from "../../../js/utils/constants/HyperNFT";

const Store = () => {
    return <PagesLayout data = {
        nftContent
    }
    />;
};

export default Store;