import { useEffect, useState } from "react";
import { TransitionGroup, CSSTransition } from "react-transition-group";
// import styles from "./Carousel.module.scss";
import React  from 'react';
import styles from "../../../css/components/Carousel/Carousel.module.scss"
import {
  HiOutlineArrowCircleLeft,
  HiOutlineArrowCircleRight,
  HiOutlineMail,
} from "react-icons/hi";
import { AiFillLinkedin } from "react-icons/ai";
import { FaTelegram } from "react-icons/fa";
function Carousel({ items, active, teamsInfo }) {
  const [state, setState] = useState({
    items,
    active,
    direction: "",
  });

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  function generateItems() {
    var items = [];

    var level = 0;

    for (var i = 0; i < state.items.length; i++) {
      var index = i;

      if (teamsInfo[index]) {
        items.push(
          <CSSTransition
            key={index}
            timeout={500}
            classNames={{
              enter: `${state.direction}-enter`,
              enterActive: `${state.direction}-enter-active`,
              exit: `${state.direction}-exit`,
              exitActive: `${state.direction}-exit-active`,
            }}
          >
            <Item
              index={index}
              teamsInfo={teamsInfo}
              className={styles.itemWrapper}
              id={state.items[index]}
              level={level}
              linkedinLink={teamsInfo[index]?.link}
            />
          </CSSTransition>
        );
      }
    }
    return items;
  }

  function moveLeft() {
    var newActive = state.active;
    newActive--;
    setState({
      ...state,
      active: newActive < 0 ? state.items.length - 1 : newActive,
      direction: "left",
    });
  }

  function moveRight() {
    var newActive = state.active;
    setState({
      ...state,
      active: (newActive + 1) % state.items.length,
      direction: "right",
    });
  }

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        margin: "4rem",
        position: "relative",
        left: "-65px",
      }}
      className={`${styles.noselect} ${styles.wrapper} `}
    >
      {/* <div
        className={`${styles.arrow} ${styles.arrow_left}`}
        onClick={moveLeft}
      >
        <HiOutlineArrowCircleLeft />
      </div> */}
      <TransitionGroup
        style={{
          display: "flex",
          flexDirection: windowWidth < 768 ? "column" : "row",

          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          gap: "1rem",
        }}
        className={`${styles.carousel} ${state.direction}`}
      >
        {generateItems()}
      </TransitionGroup>
      {/* <div
        className={`${styles.arrow_right} ${styles.arrow}`}
        onClick={moveRight}
      >
        <HiOutlineArrowCircleRight />
      </div> */}
    </div>
  );
}

function Item({ id, level, linkedinLink, teamsInfo, index }) {
  const [hover, setHover] = useState(false);
  const teamMemberInfo = teamsInfo;
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  console.log("teamMemberInfo", teamMemberInfo);
  return (
    <div
      style={{
        width: windowWidth < 768 && "361px",
      }}
      className={`${styles.item} ${styles["level" + level]}`}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <div
        style={{
          height: "100%",
          position: "relative",
          display: "flex",
          alignItems: "end",
          backgroundImage: `url(${teamsInfo[index]?.photo})`,
          borderRadius: "2rem",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        {/* <img src={} alt="" /> */}
        {hover && (
          <div className={styles.textOverlay}>
            <div className={styles.textOverlay__name}>
              <strong>{teamsInfo[index]?.fullname}</strong>
              <span>{teamsInfo[index]?.position}</span>
              <div className={styles.textOverlay__name__scoial}>
                {teamsInfo[index]?.linkedin && (
                  <a
                    href={teamsInfo[index]?.linkedin}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <AiFillLinkedin />
                  </a>
                )}
                {teamMemberInfo[index]?.email && (
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={`mailto:${teamMemberInfo[index]?.email}`}
                  >
                    <HiOutlineMail />
                  </a>
                )}
                {teamMemberInfo[index]?.telegram && (
                  <a
                    rel="noreferrer"
                    target="_blank"
                    style={{ position: "relative", top: "-1px" }}
                    href={`https://t.me/${teamMemberInfo[index]?.telegram}`}
                  >
                    <FaTelegram size={28} />
                  </a>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Carousel;
