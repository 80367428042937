import React from 'react';
import { ErrorMessage, useField } from 'formik';
import { aboutContent } from '../../utils/constants/About';

export const TextField = ({ label,showError = true, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <div className="mb-2">
      <label htmlFor={field.name} className="form-label">{label}</label>
      <input
        className={`form-control ${meta.touched && meta.error && 'is-invalid'}`}
        {...field} {...props}
        autoComplete="off"
        readOnly = {props.readOnly}
        placeholder= {props.placeholder}
        style={{height: '40px', marginBottom: '10px'}}
      />
      {showError && (<ErrorMessage component="div" name={field.name} className="error" style={{color: 'red', fontSize: '12px'}} />)}
    </div>
  )
}
