import React, { useState, useEffect, useCallback, useRef, useLayoutEffect } from 'react';
import './HistoryTrade.css';
import { toast } from 'react-toastify';
import { dateToString } from '../../../common/Common';
import service from '../../../../service/Service';

const HistoryTrade = () => {

    const [historyMe, setHistoryMe] = useState([])
    const [currentPage, setCurrentPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [total, setTotal] = useState(0);

    useEffect(() => {
        getHistoryPerPage()
    }, [currentPage])

    const getHistoryPerPage = async () => {
        try {
            let res = await service.getHistoryMe(limit, currentPage)
            setHistoryMe(res.data.data)
            const totalUsers = res.data.pagination.total
            setTotal(totalUsers);
        } catch (error) {
            toast.error(`${error}`, {
                position: 'bottom-right'
            })
        }
    }

    const handleChangePage = (page) => {
        setCurrentPage(page);
    };

    return (
        <div className="list_stacking_me">
            <div className="container-table">
                <table className="data-table-stacking">
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Name</th>
                            <th>Quantity</th>
                            <th>Type</th>
                            <th>Remain Balance</th>
                            <th>Status</th>
                            <th>Desc</th>
                        </tr>
                    </thead>
                    <tbody>
                        {historyMe.map((row, index) => (
                            <tr key={index}>
                                <td>{dateToString(row.createdAt)}</td>
                                <td>{row.coinName.toUpperCase()}</td>
                                <td>{row.quantity}</td>
                                <th>{row.type}</th>
                                <th>{row.remainBalance}</th>
                                <th>{row.status}</th>
                                <th>{row.desc}</th>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className="pagination">
                {Array.from({ length: Math.ceil(total / limit) }, (_, index) => (
                    <button
                        key={index}
                        onClick={() => handleChangePage(index + 1)}
                        className={currentPage === index + 1 ? 'active' : ''}
                    >
                        {index + 1}
                    </button>
                ))}
            </div>
        </div>
    );
}

export default HistoryTrade;
