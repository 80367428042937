export const leftCard = {
    title: "HYPERSTORE",
    img: "",
    contentText: {
        first: "THE WORLD'S FIRST",
        second: "AI SOLUTIONS",
        third: "MARKETPLACE",
    },
    buttonText: "Launch App",
    link: "https://store.hypergpt.ai/marketplace",
};

export const rightCardFirst = {
    title: "HYPERSDK",
    img: "test",
    contentText: {
        first: "ACCESS ALL AI APIS IN ONE",
        second: "SINGLE ACCOUNT",
    },
    buttonText: "Coming Soon",
    link: "/hypersdk",
};

export const rightCardSecond = {
    title: "HYPERNFT",
    img: "test",
    contentText: {
        first: "One-click minting",
        second: "for hassle-free",
        third: "NFT creation",
    },
    buttonText: "Coming Soon",
    link: "/hypernft",
};

export const rightCardThird = {
    title: "HYPERBOOST",
    img: "test",
    contentText: {
        first: "AI-BASED",
        second: "ADVERTISEMENT",
        third: "MODEL",
    },
    buttonText: "Coming Soon",
    link: "https://hyperstore.ai/",
};