import React, { useState, useEffect, useCallback, useRef, useLayoutEffect } from 'react';
import './HistoryFuture.css';
import ArrowDown from '../../../../assets/images/icons/ArrowDown.png'
import Trash from '../../../../assets/images/icons/Trash.png'
import Edit from '../../../../assets/images/icons/Edit.png'
import EditPriceView from '../../../OrderList/OpenOrder/EditPriceView';
import service from '../../../../../service/Service';
import { capitalizeFirstLetter, dateToString } from '../../../../common/Common';
import swal from 'sweetalert';

const HistoryFuture = () => {
  const [sideFilter, setSideFilter] = useState('All');
  const [openOrders, setOpenOrders] = useState([])

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [price, setPrice] = useState('0,0203');
  const [amount, setAmount] = useState('591');
  const [isShowTrade, setIsShowTrade] = useState(false)

  const [historyOrders, setHistoryOrders] = useState([])
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(0);

  const [editPosition, setEditPosition] = useState({
    x: 0,
    y: 0
  })

  useEffect(() => {
    getOpenOrders()
  }, [])

  const getOpenOrders = async () => {
    try {
      let res = await service.getHistoryFutureOrders()
      console.log('ressss', res)
      setOpenOrders(res.data.data)
      setIsShowTrade(true)
    } catch (error) {

    }
  }

  useEffect(() => {
    getHistoryPerPage()
  }, [currentPage])

  const getHistoryPerPage = async () => {
    try {
      let res = await service.getHistoryFutureOrders(limit, currentPage)
      setHistoryOrders(res.data.data)
      const totalOrders = res.data.pagination.total
      setTotal(totalOrders);
    } catch (error) {
      toast.error(`${error}`, {
        position: 'bottom-right'
      })
    }
  }

  const handleChangePage = (page) => {
    setCurrentPage(page);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleSave = (newPrice, newAmount) => {
    setPrice(newPrice);
    setAmount(newAmount);
  };

  const handleSideSelect = (event) => {
    setSideFilter(event.target.getAttribute("data-value"));
  };

  const filteredData = openOrders.filter(row =>
    (sideFilter === 'All' || row.type === sideFilter)
  );

  const deleteOrder = async (id) => {

    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this imaginary file!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then(async (willDelete) => {
        if (willDelete) {
          try {
            await service.deleteFutureWithId(id)
            swal("Poof! Your order has been deleted!", {
              icon: "success",
            });
          } catch (error) {
            swal(`${error}`, {
              icon: "error",
            });
          }
        } else { }
      });
  }

  return (
    <>
      {isShowTrade && (
        <>
          <div className="container_open_future_order">
          <div className="container-table">
            <table className="data-table-future">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Pair</th>
                  {/* <th>
                    <div className="dropdown">
                      <button className="dropbtn">Side <img src={ArrowDown} width={16} height={16} /></button>
                      <div className="dropdown-content">
                        <span className={`${sideFilter === 'All' ? 'selected-dropdown' : ''}`} data-value="All" onClick={handleSideSelect}>All</span>
                        <span className={`${sideFilter === 'buy' ? 'selected-dropdown' : ''}`} data-value="buy" onClick={handleSideSelect}>Buy</span>
                        <span className={`${sideFilter === 'sell' ? 'selected-dropdown' : ''}`} data-value="sell" onClick={handleSideSelect}>Sell</span>
                      </div>
                    </div>
                  </th> */}
                  <th>Quanity</th>
                  <th>Expect Price</th>
                  <th>Lost Stop Price</th>
                  <th>Type</th>
                  <th>Order type</th>
                  <th>Leverage</th>
                  <th>PNL</th>
                  <th>Closing Price</th>
                  <th>Desc</th>
                </tr>
              </thead>
              <tbody>
                {historyOrders.map((row, index) => (
                  <tr key={index}>
                    <td>{dateToString(row.updatedAt)}</td>
                    <td>{row.coinName}</td>
                    <td>{row.quantity}</td>
                    <td>{row.expectPrice} </td>
                    <td>{row.lossStopPrice}</td>
                    <td>{capitalizeFirstLetter(row.type)}</td>
                    <td>{capitalizeFirstLetter(row.orderType)}</td>
                    <td>{row.leverage}x</td>
                    <td>{row.PNLClosed}</td>
                    <td>{row.closingPrice}</td>
                    <td>{capitalizeFirstLetter(row.desc)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            </div>

          </div>

          <div className="pagination">
            {Array.from({ length: Math.ceil(total / limit) }, (_, index) => (
              <button
                key={index}
                onClick={() => handleChangePage(index + 1)}
                className={currentPage === index + 1 ? 'active' : ''}
              >
                {index + 1}
              </button>
            ))}
          </div>
        </>
      )}

    </>

  );
}

export default HistoryFuture;
