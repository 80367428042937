import React, { useState, useEffect } from 'react';
import styles from './Balance.module.scss';
import service from '../../../../service/Service';
import {capitalizeFirstLetter} from '../../../../js/common/Common'

const Balance = ({ isVisible }) => {
    const [items, setItems] = useState([]);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(5);
    const [totalPages, setTotalPages] = useState(1);
    const [visibleBalance, setVisibleBalance] = useState(isVisible)


    useEffect(() => {
        fetchData();
    }, [page, limit]);

    useEffect(() => {
        setVisibleBalance(isVisible)
    }, [isVisible]);


    const fetchData = async () => {
        try {
            const response = await service.getWalletMe(limit, page)
            setItems(response.data.data);
            let total = response.data.pagination.total
            setTotalPages(Math.ceil(total / limit)); // Giả sử API trả về tổng số trang .data.pagination.total
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleNextPage = () => {
        if (page < totalPages) {
            setPage(page + 1);
        }
    };

    const handlePreviousPage = () => {
        if (page > 1) {
            setPage(page - 1);
        }
    };

    return (
        <>
            {visibleBalance &&
                <div className={styles.container}>
                    <div className={styles.content_items}>
                        {items.map(item => (
                            <div className={styles.item_balance} key={item.id}>
                                <span>{item.coinName.toUpperCase()}</span>
                                <span>{item.quantity}</span>
                            </div>
                        ))}
                    </div>
                    {totalPages > 1 &&
                        <div className={styles.pagination_controls}>
                            <button onClick={handlePreviousPage} disabled={page === 1}>
                                ˂˂
                            </button>
                            <span>Page {page} of {totalPages}</span>
                            <button onClick={handleNextPage} disabled={page === totalPages}>
                                ˃˃
                            </button>
                        </div>
                    }
                </div>
            }

        </>

    );
};

export default Balance;
