import './SwitchTab.css'
import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TradeView from '../Spot/TradeView'
import TabPanel from '@mui/lab/TabPanel';
import { FaUserGraduate } from 'react-icons/fa';
import FutureView from '../Future/FutureView';
import StackingView from '../Stacking/StackingView'
import service from '../../../service/Service';
import { useDispatch } from 'react-redux';
import { avlbUSDT } from '../../common/redux/TradeAction.js';
const SwitchTab = ({ onTabChange }) => {
    const [activeButton, setActiveButton] = useState('spot'); // 1 is buy, 2 is sell
    const dispatch = useDispatch();

    useEffect(() => {
        getWalletAvlb()
    })

    const getWalletAvlb = async () => {
        try {
            let res = await service.getWalletByCoin('usdt')
            console.log('==>', res)
            dispatch(avlbUSDT(res.data.data[0].quantity))
        } catch (err) { }
    }

    const handleChange = (event, newValue) => {
        setActiveButton(newValue);
        onTabChange(newValue)
    };
    return (
        <div className='container'>
            <div>
                <Box sx={{
                    width: '100%', typography: 'body1', "& .MuiTabPanel-root": {
                        padding: '0px',
                    }
                }}>
                    <TabContext value={activeButton} >
                        <Box sx={{
                            borderBottom: 1, borderColor: '', position: "relative", "& .MuiTabs-indicator": {
                                position: "absolute",
                                top: 0,
                                margrinTop: '15px',
                                width: "100%",
                                backgroundColor: 'white',
                                height: '2px'
                            }
                        }}>
                            <TabList
                                onChange={handleChange}
                                aria-label="lab API tabs example"
                                sx={{

                                    "& .MuiTab-root": {
                                        minHeight: "auto", textTransform: "none", color: "#808080",
                                        '&.Mui-selected': {
                                            color: 'white',
                                        }
                                    }
                                }}
                            >
                                <Tab
                                    label="Spot"
                                    value="spot"
                                    sx={{ minHeight: "auto", width: '33%', background: `${activeButton == 'spot' ? '#0e0e0e' : '#191919'}` }}
                                />
                                <Tab
                                    label="Future"
                                    value="future"
                                    sx={{ minHeight: "auto", width: '33%', background: `${activeButton == 'future' ? '#0e0e0e' : '#191919'}` }}
                                />
                                <Tab
                                    label="Stacking"
                                    value="stacking"
                                    sx={{ minHeight: "auto", width: '33%', background: `${activeButton == 'stacking' ? '#0e0e0e' : '#191919'}` }}
                                />
                            </TabList>
                        </Box>

                        <TabPanel value="spot" >
                            <TradeView />
                        </TabPanel>
                        <TabPanel value="future">
                            <FutureView />
                        </TabPanel>
                        <TabPanel value="stacking">
                            <StackingView />
                        </TabPanel>
                    </TabContext>
                </Box>
            </div>
        </div>
    );
}

export default SwitchTab;