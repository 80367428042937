import React, { useState } from "react";
import styles from '../../../js/components/Login/ForgotPassword.module.scss'
import { Formik, Form } from "formik";
import { TextField } from "./../TextField/TextField";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import Button from "../Button/Button";
import LeftArrow from '../../../js/assets/images/icons/LeftArrow.png'
import { toast } from "react-toastify";
import service from "../../../service/Service";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";


const forgotPasswordPeriod = {
    inputEmail: 'inputEmail',
    inputOtp: 'inputOtp',
    inputNewPassword: 'inputNewPassword'
};

const buttonTitles = {
    inputEmail: 'Next',
    inputOtp: 'Verify',
    inputNewPassword: 'Create new password'
};

const ForgotPassword = ({ backToLogin }) => {

    const auth = getAuth();
    const [period, setPeriod] = useState('inputNewPassword');

    const getButtonTitle = (period) => {
        return buttonTitles[forgotPasswordPeriod[period]];
    }

    const handleInputEmail = async (email) => {
        try {
            let res = await service.forgotPassword(email)
            toast.success(`OTP have been sent at ${email}`, {
                position: 'bottom-right'
            })
            setPeriod('inputOtp')
        } catch (error) {
            toast.error(`${error}`, {
                position: 'bottom-right'
            })
        }
    }

    const handleInputOtp = async (email, otp) => {
        let otpInt = parseInt(otp, 10)
        try {
            let res = await service.verifyOtp(email, otpInt)
            console.log('resss', res)
            setPeriod('inputNewPassword')
        } catch (error) {
            // console.log()
            toast.error(`${error}`, {
                position: 'bottom-right'
            })
        }
    }

    const handleInputNewPassword = async (email, password) => {
        try {
            let res = await service.resetPassword(email, password)
            toast.success(`Reset password successfully`, {
                position: 'bottom-right'
            })
            backToLogin()
        } catch (error) {
            toast.error(`${error}`, {
                position: 'bottom-right'
            })
        }
    }
    const handleForgotPassword = async (data) => {

        switch (period) {
            case forgotPasswordPeriod.inputEmail:
                handleInputEmail(data.email)
                break
            case forgotPasswordPeriod.inputOtp:
                handleInputOtp(data.email, data.otp)
                break
            case forgotPasswordPeriod.inputNewPassword:
                handleInputNewPassword(data.email, data.password)
                break
        }
    }
    const validate = Yup.object().shape({
        email: Yup.string().email('Invalid email').required('Required'),
    });

    return (
        <div className={styles.forgot_password}>
            <p> Reset your password</p>
            <Formik
                initialValues={{
                    email: "",
                    otp: "",
                    password: ""
                }}
                validationSchema={validate}
                onSubmit={(data) => {
                    handleForgotPassword(data)
                }}
            >
                {(formik) => (
                    <Form className={styles.forgot_form}>
                        <div className="form-outline mb-4  ">
                            <TextField
                                label="Email"
                                name="email"
                                type="text"
                                placeholder="Enter your email address"
                            />

                            {(period === forgotPasswordPeriod.inputOtp || period === forgotPasswordPeriod.inputNewPassword) && (
                                <div className="form-outline mb-4 ">
                                    <TextField
                                        label="OTP"
                                        name="otp"
                                        type="text"
                                        placeholder="Enter OTP code"
                                    />
                                </div>
                            )}

                            {period === forgotPasswordPeriod.inputNewPassword && (
                                <div className="form-outline mb-4 ">
                                    <TextField
                                        label="New password"
                                        name="password"
                                        type="password"
                                        placeholder="Enter new password"
                                    />
                                </div>
                            )}
                        </div>
                        <button type="submit" className={styles.btn_grad}>
                            {getButtonTitle(period)}
                        </button>
                        <div className={styles.backPrevious}>
                            <p onClick={backToLogin} className={styles.backButton} >Back</p>
                        </div>
                    </Form>

                )}
            </Formik>

        </div>
    );
}

export default ForgotPassword;