import React, { useState } from 'react';
import './Profile.css';
import FormProfile from './FormProfile';
import WalletInfo from './WalletInfo';
import Balance from './Wallet/Balance';

const Profile = () => {

    const [isVisibaleBalance, setIsVisibaleBalance] = useState(false)

    const toggleVisibility = () => {
        setIsVisibaleBalance(!isVisibaleBalance);
    };

    return (
        <>
            <div className="profile-container">
                <div className="profile-overview">
                    <FormProfile />

                </div>
                <div className="accounts">
                    <WalletInfo toggleVisibility={toggleVisibility} />
                    <Balance isVisible={isVisibaleBalance} />
                </div>
            </div>
        </>

    );
};

export default Profile;