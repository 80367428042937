import React from "react";
import styles from "../../../css/pages/Pricing/Pricing.module.scss";
import { getPackages } from "../../services/Pricing";
import { useEffect, useState } from "react";

const Pricing = () => {
  const [packages, setPackages] = useState([]);

  const getPricingPackages = async () => {
    try {
      const response = await getPackages();
      const sortedPackages = response.sort((a, b) => a.order - b.order);
      setPackages(sortedPackages);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    // top scroll to
    getPricingPackages();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [packages]);

  return (
    <div className={styles.container}>
      {packages.map((pkg) => (
        <div key={pkg.id} className={styles.card}>
          <div className={styles.cardContent}>
            <h2>{pkg.name} Package</h2>
            <span>
              <strong>${pkg.price}</strong> <p>/</p>{" "}
              <p className={styles.credit}>{pkg.credit}</p>
            </span>
            <p>{pkg.credit} Credit</p>
            <p>{pkg.line2}</p>
            <p>{pkg.line3}</p>
          </div>
          <button
            onClick={() => {
              window.open("https://store.hypergpt.ai/pricing", "_blank");
            }}
          >
            Buy Now
          </button>
        </div>
      ))}
    </div>
  );
};

export default Pricing;
