import Axios, { AxiosError, HttpStatusCode } from "axios";
import service from "./Service";
import { swap } from "formik";

// https://api.github.com/users

export const axios = Axios.create({
    baseURL: 'https://server-crypto.sbs/'
});

axios.interceptors.request.use(
    async (config) => {
        config.headers.Authorization = `Bearer ${localStorage.getItem('accessToken')}`;
        return config;
    }
)

var count = 0

axios.interceptors.response.use(
    response => {
        return response;
    },
    async (error) => {
        // Handle errors
        switch (error.code) {
            case AxiosError.ERR_NETWORK:
                errorFinal = error.message
                break
            default:
                break
        }

        if (error.response !== null && error.response !== undefined) {
            const data = error.response.data
            var errorFinal = ""
            switch (error.response.status) {
                case HttpStatusCode.BadRequest:  // 400
                    if (data.errors != null) {
                        errorFinal = data.errors.map(error => error.error).join('\n');
                    } else {
                        errorFinal = data.message
                    }
                    break
                case HttpStatusCode.Unauthorized: // 401
                    count++;
                    try {
                        console.log(count);
                    if (count > 1) {
                        localStorage.setItem('accessToken', '')
                        return Promise.reject(error)
                    }
                        const res = await service.refreshToken(localStorage.getItem('refreshToken'))
                        localStorage.setItem('accessToken', res.data.data.accessToken)
                        localStorage.setItem('refreshToken', res.data.data.refreshToken)
                        error.config.headers.Authorization = `Bearer ${res.data.data.accessToken}`
                        return axios(error.config)
                    }
                    catch (err) {
                        localStorage.setItem('accessToken', '')
                        errorFinal = data.message
                        throw errorFinal
                    }
                case HttpStatusCode.NotFound:
                    errorFinal = data.message
                    break
                case HttpStatusCode.InternalServerError:
                    errorFinal = "Internal server"
                    break;
                case HttpStatusCode.Forbidden:
                    break;
                default:
                    break;
            }
        }

        throw errorFinal;
    },
);
// http://ec2-54-175-103-162.compute-1.amazonaws.com:3000/v1/auth/user/register
// axios.interceptors.request.use(
//     async (config) => {
//         config.headers.Authorization = `Bearer ${localStorage.getItem('accessToken')}`;
//         return config;
//     }
// );