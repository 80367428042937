import { format } from 'date-fns';

export const removeAfterLastSlash = (str) => {
  const index = str.lastIndexOf('/');
  return index === -1 ? str : str.substring(0, index);
}

export const removeBeforeLastSlash = (str) => {
  return str.split('/')[1];
}

export const removeCenterSlash = (str) => {
  return str.replace("/", "").toLowerCase();
}

export const dateToString = (date) => {
  return format(date, 'yyyy-MM-dd HH:mm:ss');
}

export const capitalizeFirstLetter = (string) => {
  if (!string) return ''; // Kiểm tra chuỗi rỗng hoặc không xác định
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const roundDown = (number, decimalPlaces = 2) => {
  const numberStr = number.toString();
  const decimalPart = numberStr.split('.')[1];
  const factor = Math.pow(10, decimalPlaces);
  
  // Nếu phần thập phân có nhiều hơn 2 chữ số
  if (decimalPart && decimalPart.length > 2) {
    return parseFloat(Math.floor(number * factor) / factor );
  }
  return number;
}

export const roundUp = (number) => {
  return parseFloat(Math.ceil(number * 100) / 100);
}

export const  getCurrentTime = () => {
  const now = new Date();
  
  return now.toUTCString()
}

export const convertToTimestamp = (dateString) => {
  const date = new Date(dateString);
  const timestamp = Math.floor(date.getTime() / 1000);
  return timestamp;
}