import React, {
    useEffect,
    useState
} from "react";
// import data from "../../../../js/utils/"
// import data from "../../../utils/partners.json";
import Marquee from "react-fast-marquee";
import styles from "../../../../css/pages/Home/components/Marquee.module.scss";

const Index = () => {
    const [partnersLogo, setPartnersLogo] = useState([]);

    useEffect(() => {
        fetch("https://api.hypergpt.ai/app/partners")
            .then((response) => response.json())
            .then((data) => {
                const filteredData = data.filter((item) => item.isactive);

                const sortedData = filteredData.sort((a, b) => a.order - b.order);

                setPartnersLogo(sortedData);
            })
            .catch((error) => console.error("Error:", error));
    }, []);

    console.log(partnersLogo);

    return ( <
        Marquee > {
            partnersLogo.map((key, value) => ( <
                img className = {
                    styles.Marquee__img
                }
                key = {
                    key
                }
                src = {
                    key.image_url
                }
                alt = {
                    key
                }
                />
            ))
        } <
        /Marquee>
    );
};

export default Index;