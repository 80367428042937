
import { useState } from "react"
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "./../../../firebase/firebase";
import styles from '../../../css/components/Login/Login.module.scss'
import RotboLogin from '../../../js/assets/images/icons/RobotLogin.svg'
import FormLogin from "./FormLogin"
import ForgotPassword from "./ForgotPassword"
import Register from "../Register/Register";


const Login = ({ children, dismissLogin }) => {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [showForgotPassword, setShowForgotPassword] = useState(false)
  const [createAcc, setCreateAcc] = useState(false)
  const authType = {
    Login: 'login',
    ForgotPassword: 'forgotPassword',
    CreateAcc: 'createAcc'
  };
  const [authContent, setAuthContent] = useState(authType.Login);


  const handleforgotPassword = () => {
    // e.preventDefault()
    // setShowForgotPassword(true)
    // console.log('12345')
    setAuthContent(authType.ForgotPassword)

  }

  const handleCreateAccount = (e) => {
    e.preventDefault()
    // setCreateAcc(true)
    setAuthContent(authType.CreateAcc)
  }

  const handleBackToLogin = () => {
    // show login <==> showForgotPassword = false
    // setShowForgotPassword(false)
    setAuthContent(authType.Login)
  }

  const handleLoginSuccess = () => {
    dismissLogin()
  }

  const authContentView = (authContent) => {
    switch (authContent) {
      case authType.Login:
        return (
          <>
            <FormLogin forgotPassword={handleforgotPassword} createAccount={handleCreateAccount} handleBackToLogin={handleBackToLogin} LoginSuccess={handleLoginSuccess}/>
          </>

        )
      case authType.ForgotPassword:
      
        return (<ForgotPassword backToLogin={handleBackToLogin} />)
      case authType.CreateAcc:
     
        return (<Register dismissRegister={handleBackToLogin} goToLogin={handleBackToLogin} />)
        // return (<p>123456</p>)
    }
  }
  return (
    <div className={styles.popup_inner}>
      <div className={styles.logo_login}>
        <img src={RotboLogin}></img>
      </div>
      <div className={styles.login_content}>
        <h3>Welcome to HyperStore!</h3>
        <p> Are you ready to explore the most popular and cutting-edge AI applications? Join our community and be a part of the future of AI.</p>
        {/* {showForgotPassword ? <ForgotPassword backToLogin={handleBackToLogin} /> : <FormLogin forgotPassword={handleforgotPassword} createAccount={handleCreateAccount} handleLogin={handleLogin} /> } */}
        {authContentView(authContent)}
      </div>
    </div>

  )
}

export default Login