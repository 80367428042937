import React, { useState } from 'react';
import './EditPriceView.css';
import TextFieldPrice from '../../TextField/TextFieldPrice';

const EditPriceView = ({ isOpen, onClose, onSave, price, amount }) => {
  const [editPrice, setEditPrice] = useState(price);
  const [editAmount, setEditAmount] = useState(amount);

  const handleSave = () => {
    onSave(editPrice, editAmount);
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="edit-view">
      <div className="edit-field">
        <TextFieldPrice title='Price' placeholder='' value={price} type="number"/>
        <TextFieldPrice title='Amount' placeholder='' value={amount} type="number"/>
      </div>
      <div className="edit-actions">
        <button onClick={onClose}>Cancel</button>
        <button onClick={handleSave}>Confirm</button>
      </div>
    </div>
  );
};

export default EditPriceView;
