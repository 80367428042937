import './FutureView.css'
import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import FutureLimitView from './FutureLimitView';

const FutureView = () => {

    const [activeButton, setActiveButton] = useState(1); // 1 is buy, 2 is sell
    const [typeTrade, setTypeTrade] = React.useState('1'); // 1 is market, 2 is limit

    const handleChange = (event, newValue) => {
        setTypeTrade(newValue);
    };

    const handleClick = (buttonNumber) => {
        setActiveButton(buttonNumber);
    };

    return (
        <div className='container'>
            <div className="button-group-container">
                <button
                    className={`button button-1 ${activeButton === 1 ? 'active' : ''}`}
                    onClick={() => handleClick(1)}
                >
                    Buy/ Long
                </button>
                <button
                    className={`button button-2 ${activeButton === 2 ? 'active' : ''}`}
                    onClick={() => handleClick(2)}
                >
                    Sell/ Short
                </button>
            </div>

            <div>


                <Box sx={{ width: '100%', typography: 'body1' }}>
                    <TabContext value={typeTrade} >
                        <Box sx={{
                            borderBottom: 1, borderColor: '', position: "relative", "& .MuiTabs-indicator": {
                                position: "absolute",
                                bottom: 15,
                                margrinTop: '15px',
                                width: "100%",
                                backgroundColor: 'white'
                            }
                        }}>
                            <TabList
                                onChange={handleChange}
                                aria-label="lab API tabs example"
                                sx={{
                                    "& .MuiTab-root": {
                                        minHeight: "auto", maxHeight: '35px', textTransform: "none", color: "#808080",
                                        '&.Mui-selected': {
                                            color: 'white',
                                        }
                                    }
                                }}
                            >
                                <Tab
                                    label="Market"
                                    value="1"
                                    sx={{ minHeight: "auto", maxHeight: '35px' }}
                                />
                                <Tab
                                    label="Limit"
                                    value="3"
                                    sx={{ minHeight: "auto", maxHeight: '35px', color: 'white', fontWeight: '700' }}
                                />
                            </TabList>
                            <div className='tabBorder' />
                        </Box>

                        <TabPanel value="1">
                            {activeButton === 1 ? (
                                <FutureLimitView type="market" orderType="long" />
                            ) : (
                                <FutureLimitView type="market" orderType="short" />
                            )}
                        </TabPanel>
                        <TabPanel value="3">
                            {activeButton === 1 ? (
                                <FutureLimitView type="limit" orderType="long" />
                            ) : (
                                <FutureLimitView type="limit" orderType="short" />
                            )}
                        </TabPanel>
                    </TabContext>
                </Box>
            </div>
        </div>
    );
}

export default FutureView;
