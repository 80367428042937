import Sidebar from "./Sidebar"
import './Admin.scss'
import Dashboard from "./Dashboard"
import { useEffect, useState } from "react"
import Notification1 from "./Notification1"

const Admin = () => {
    const [option, setOption] = useState(1)
    let render = ''

    switch(option){
        case 1: render = (
            <div className="admin-dashboard">
                <Dashboard></Dashboard>
            </div>
        )
        break
        case 2: render = (
            <div className="admin-dashboard">
                <Notification1></Notification1>
            </div>
        )
    }

    return (
        <div className="admin">
            <div className="admin-sidebar">
                <Sidebar setOption={setOption}></Sidebar>
            </div>
            {render}
        </div>
    )
}

export default Admin
