// import {
//     Access,
//     AccessSecond,
//     Credit,
//     Payment
// } from "../../../js/pages/";
import Access from '../../../media/Access.8062690c985591acdc450ba76b26f0ad.svg'
import AccessSecond from '../../../media/AccessSecond.111ae73010f42631e3a0649954240454.svg'
import Credit from '../../../media/Credit.7b5555fcc93f916029c1955cd43cf720.svg'
import Payment from '../../../media/Payment.293e41f2b4cce1cafe07d5835c751d50.svg'

 import logo from "../../../logo.svg"
export const cards = [{
        title: "Payment method",
        desc: "$HGPT can be used as a method of payment within the AI Marketplace ecosystem, allowing users to purchase AI products and services.",
        icon: Payment,
    },
    {
        title: "Access to premium features",
        desc: "$HGPT can be used to unlock access to premium features within the AI Marketplace, such as advanced analytics, data visualization tools, or personalized recommendations.",
        icon: AccessSecond,
    },

    {
        title: "Access to exclusive content",
        desc: "$HGPT can be used to unlock access to exclusive content, such as premium research reports or AI models that are only available to token holders.",
        icon: Access,
    },
    {
        title: "Credit model",
        desc: "With $HGPT, terminate monthly subscriptions and pay as you go. This way, you can eliminate unnecessary expenses and pay for the AI solutions you use.",
        icon: Credit,
    },
];