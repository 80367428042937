import React, { useState } from "react";
import styles from '../../../js/components/Login/FormLogin.module.scss'
import { Formik, Form } from "formik";
import { TextField } from "./../TextField/TextField";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import Button from "../Button/Button";
import google from "../../../js/assets/images/logos/google.svg"
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth, db } from "./../../../firebase/firebase";
import { signInWithGooglePopup } from "./../../../firebase/firebase"
import { doc, getDoc, setDoc } from "firebase/firestore";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"
import service from "../../../service/Service";

const FormLogin = ({ forgotPassword, createAccount, LoginSuccess, loginGoogle }) => {

    const [isLoginFail, setIsLoginFail] = useState(false)

    const handleLoginGoogle = async () => {
        try {
            const response = await signInWithGooglePopup();
            console.log(response);
            const user = auth.currentUser;
            console.log(user.displayName);
            if (user) {
                const docRef = doc(db, "Users", user.uid);
                const docSnap = await getDoc(docRef);
                if (!docSnap.exists()) {
                    await setDoc(doc(db, "Users", user.uid), {
                        email: user.email,
                        name: user.displayName,
                    });
                }
                toast.success("Login successfully", {
                    position: 'bottom-right'
                }) 
                LoginSuccess()

            }
        } catch (error) {
            toast.error("Login fail", {
                position: 'bottom-right'
            })
            console.log(error.message);
        }

    }
    const handleLogin = async (data) => {
        try {
            let res = await service.loginAuth( data.email, data.password)
            console.log('ress.........', res)
            localStorage.setItem('accessToken', res.data.data.accessToken);
            localStorage.setItem('refreshToken', res.data.data.refreshToken);
            setIsLoginFail(false)
            toast.success("Login successfully", {
                position: 'bottom-right'
            }) 
            LoginSuccess()
        } catch (error) {
            setIsLoginFail(true)
            toast.error(`${error}`, {
                position: 'bottom-right'
            }) 
        }
    };
    const validate = Yup.object().shape({
        password: Yup.string()
            .required('Required')
            .required('Required'),
        email: Yup.string().email('Invalid email').required('Required'),
    });


    return (
        <div className={styles.container_login}>
            <Formik
                initialValues={{
                    email: "",
                    password: "",
                }}
                validationSchema={validate}
                validateOnBlur={true}
                onSubmit={(values) => {
                    setIsLoginFail(false)
                    handleLogin(values)
                }}
            >
                {(formik) => (
                    <Form >
                        <div className="form-outline mb-4 ">
                            <TextField
                                label="Email"
                                name="email"
                                type="text"
                                placeholder="Please enter your email address"
                            />
                        </div>
                        <div className="form-outline mb-4">
                            <TextField
                                label="Password"
                                name="password"
                                type="password"
                                placeholder="Please enter your password" />
                        </div>
                        {/* {isLoginFail ? (<p style={{ color: 'red', padding: '10px 0px' }}>Email or password incorrect</p>) : (<></>)
                        } */}

                        <button type="submit" className={styles.btn_grad}>
                            Sign in
                        </button>

                    </Form>

                )}
            </Formik>


            <p>Continue with</p>
            <div className={styles.login_google}>

                <button className={styles.button_login_gg} onClick={handleLoginGoogle}>
                    <img src={google} className={styles.icon_gg}></img>
                    CONTINUE WITH GOOGLE
                </button>
            </div>

            <div className={styles.sub_login}>
                <p onClick={createAccount} >Don't have an account?</p>
                <p onClick={forgotPassword}>Forgot password</p>
            </div>
        </div>
    );
}

export default FormLogin;