import styles from './ContainerChartView.module.scss'
import OrderView from './Order/OrderView';
import SwitchTab from './Tab/SwitchTab';
import { useState } from 'react';
import OrderListFuture from './Future/OrderList/OrderListFuture';
import TradingViewWidget from './TradingViewWidget/TradingViewWidget';
import { Provider } from 'react-redux';
import { store } from './../../js/common/redux/store';
import StackingMe from './Stacking/StackingMe';

const ContainerChartView = () => {
    const [activeTab, setActiveTab] = useState('spot');

    const handleTabChange = (newTab) => {
        setActiveTab(newTab);
    };

    const orderList = () => {
        switch (activeTab) {
            case 'spot':
                return (<OrderView />)
            case 'future':
                return (
                    <OrderListFuture />
                )
            case 'stacking':
                return (
                    <StackingMe />
                )
        }
    }
    return (
        <Provider store={store}>
            <div className={styles.container}>
                <div className={styles.horizontal}>
                    <div className={styles.chartView}>
                        <TradingViewWidget />
                    </div>
                    <div className={styles.tradeView}>
                        <SwitchTab onTabChange={handleTabChange} />
                    </div>
                </div>
                <div>
                    {orderList()}
                </div>
            </div>
        </Provider>
    );
}

export default ContainerChartView;