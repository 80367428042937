import styles from './BuyLimitView.module.scss'
import { useState, useEffect } from 'react';
import SliderPrice from '../../components/Chart/SliderPrice';
import TextFieldPrice from '../TextField/TextFieldPrice';
import Button from '../../components/Button/Button';
import eventEmitter from '../../common/eventEmitter/eventEmitter';
import { useSelector } from 'react-redux';
import service from '../../../service/Service';
import { formatNumber } from '../../common/NumberFormat';
import { toast } from "react-toastify";
import { removeAfterLastSlash, roundDown, roundUp } from '../../common/Common';

const BuyLimitView = () => {

  const [amount, setAmount] = useState(0)
  const [percentValue, setPercentValue] = useState(0)
  const [total, setTotal] = useState(0)
  const nameSymbol = useSelector((state) => state.trade.nameSymbol)
  const [titleButton, setTitleButton] = useState(`Buy ${nameSymbol}`)
  const priceSymbol = useSelector((state) => state.trade.priceSymbol);
  const [price, setPrice] = useState(priceSymbol)
  const [expectPrice, setExpectPrice] = useState(0)
  const avlb = useSelector((state) => state.trade.avlbUSDT)
  const [disableButton, setDisableButton] = useState(true)

  useEffect(() => {
    setTitleButton(`Buy ${nameSymbol}`)
    resetAllValue()
  }, [nameSymbol]);

  useEffect(() => {
    setPrice(formatNumber(priceSymbol))
  }, [priceSymbol]);

  useEffect(() => {
    if (amount <= 0 || amount === '' || total <= 0 || total === '' || expectPrice <= 0 || expectPrice === '' || total > avlb) {
      setDisableButton(true)
    } else {
      setDisableButton(false)
    }
  }, [amount, total, expectPrice]);

  useEffect(()=> {
    setTotal(roundUp(amount * expectPrice))
  }, [expectPrice])
  
  const resetAllValue = () => {
    setPercentValue(0)
    setAmount(0)
    setTotal(0)
    setExpectPrice(formatNumber(priceSymbol))
  }

  const getAmount = (data) => {
    setAmount(data)
    let totalTemp = expectPrice * parseFloat(data)
    setTotal(roundUp(totalTemp))
    setPercentValue(0)
  }

  const handleSlider = (data) => {
    setPercentValue(data)
    setTotal(roundDown(data * avlb / 100))
    setAmount(roundUp((data * avlb / 100) / expectPrice))
  }

  const getTotal = (data) => {
    setTotal(roundUp(data))
    setAmount(roundDown(data / expectPrice))
    setPercentValue(0)
  }

  const getExpectPrice = (data) => {
    setExpectPrice(data)
  }

  const handleConfirm = async () => {
    if (amount == 0 || expectPrice == 0) {
      toast.warning(`Please fill out all the fields`, {
        position: 'bottom-right'
      })
    } else {
      let value = {
        coinName: nameSymbol,
        totalPay: parseFloat(total),
        quantity: parseFloat(amount),
        expectPrice: parseFloat(expectPrice),
        type: "buy",                                 // "buy|sell"
        lossStopPrice: null  // buy thì sẽ bằng null
      }
      try {
        let res = await service.addLimit(value)
        toast.success(`Buy ${nameSymbol} successfully`, {
          position: 'bottom-right'
        })
      } catch (error) {
        console.log('catch', error)
        toast.error(`${error}`, {
          position: 'bottom-right'
        })
      }
    } 
  }

  return (
    <div className={styles.container}>
      <TextFieldPrice title='Price' placeholder='Enter' isReadOnly={true} value={price} type="text"/>
      <TextFieldPrice title='Expect price' placeholder='Enter' value={expectPrice} onChange={getExpectPrice} type="text" max={7}/>
      <TextFieldPrice title='Amount' placeholder='Enter' value={amount} onChange={getAmount} type="text" unit={removeAfterLastSlash(nameSymbol)}/>
      <SliderPrice value={percentValue} handleSliderChange={handleSlider} />
      <div className={styles.availablePrice}>
        <p>Avlb</p>
        <p>{avlb} USDT</p>
      </div>
      <TextFieldPrice title='Total' placeholder='Enter' value={total} onChange={getTotal} type="text" unit='USDT'/>
      <div style={{ marginTop: '30px' }}>
        <Button
          onClick={() => {
            handleConfirm()
          }}
          isDisabled={disableButton}
          title={titleButton}
          size="large"
          isGradient={true}
          key={1}
          style={{ width: '100%' }}
        />
      </div>
    </div>
  );
}

export default BuyLimitView;
