import Api from "./Api";
import { axios } from "./axios";
import { removeAfterLastSlash } from "../js/common/Common";


const service = {
    registerAuth: (email, password, inviteCode) => {
        return axios.post(Api.REGISTER,
            {
                email: email,
                password: password,
                inviteCode: inviteCode
            })
    },
    loginAuth: (email, password) => {
        return axios.post(Api.LOGIN,
            {
                email: email,
                password: password
            })
    },
    verifyOtp: (email, otp) => {
        return axios.post(Api.VERIFYOTP,
            {
                email: email,
                otp: otp
            })
    },
    forgotPassword: (email) => {
        return axios.post(Api.FORGOTPASSWORD,
            {
                email: email
            }
        )
    },
    resetPassword: (email, password) => {
        return axios.post(Api.FORGOTPASSWORD,
            {
                email: email,
                password: password
            }
        )
    },
    logout: () => {
        return axios.post(Api.LOGOUT,
            {
                refreshToken: localStorage.getItem('refreshToken')
            }
        )
    },
    uploadImage: (image) => {
        return axios.post(Api.UPLOADIMAGE, image, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    },

    uploadMultipleImages: (images) => {
        return axios.post(Api.UPLOADIMAGES, images, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    },

    userMe: () => {
        return axios.get(Api.USERME)
    },
    updateProfile: (profile, avatar) => {
        return axios.put(Api.UPDATEPROFILE,
            {
                avatar: avatar,
                fullname: profile.fullName,
                phone: profile.phoneNumber,
                birthDay: profile.birthDay,
                address: profile.address,
                country: profile.country,
            }
        )
    },
    getAllUsers: (limit, page) => {
        return axios.get(Api.USER, {
            headers: {
                'x-lang': 'vi'
            },
            params: {
                limit: limit,
                page: page,
                order: { createdAt: 'ASC' }
            }
        })
    },
    sendOneNotification: (content, userId) => {
        return axios.post(Api.NOTIFICATION_ONE, {
            content: content,
            userId: userId
        })
    },
    refreshToken: (value) => {
        return axios.post(Api.TOKEN, {
            refreshToken: value
        })
    },
    requestTransfer: (coinName, quantity, type, screenShot) => {
        return axios.post(Api.TRANSFER, {
            coinName: coinName,
            quantity: quantity,
            type: type,
            proofURL: screenShot
        })
    },
    getAllNotifications: (limit, page) => {
        return axios.get(Api.NOTIFICATION, {
            headers: {
                'x-lang': 'vi'
            },
            params: {
                limit: limit,
                page: page,
                order: { createdAt: 'DESC' }
            }
        })
    },
    getNotificationById: (id) => {
        return axios.get(`${Api.NOTIFICATION}/${id}`)
    },
    // limit
    addLimit: (value) => {
        return axios.post(Api.LIMIT,
            {
                coinName: removeAfterLastSlash(value.coinName),
                totalPay: value.totalPay,
                quantity: value.quantity,
                expectPrice: value.expectPrice,
                type: value.type,                                 // "buy|sell"
                lossStopPrice: value.lossStopPrice
            }
        )
    },

    // don't use
    stopLimit: () => {
        return axios.delete(Api.LIMIT,
            {
                coinName: removeAfterLastSlash(value.coinName),
                totalPay: value.totalPay,
                quantity: value.quantity,
                expectPrice: value.expectPrice,
                type: value.type,                                 // "buy|sell"
                lossStopPrice: value.lossStopPrice
            }
        )
    },
    getLimitMe: (limit, page) => {
        return axios.get(Api.LIMITME, {
            params: {
                limit: limit,
                page: page,
                order: { createdAt: 'DESC' }
            }
        })
    },

    getSellLimit: (coinName) => {
        return axios.get(Api.LIMITME, {
            params: {
                limit: Number.MAX_SAFE_INTEGER,
                page: 1,
                filter: { 
                    "coinName": coinName,
                    "type": "buy"
                }
            }
        })
    },

    getHistoryLimitOrders: (limit, page) => {
        return axios.get(Api.LIMITHISTORY, {
            params: {
                limit: limit,
                page: page,
                order: { createdAt: 'DESC' }
            }
        })
    },
    deleteLimitWithId: (id) => {
        return axios.post(`v1/command/limit/${id}/cancel`)
        // return axios.post(`v1/command/future/${id}/close`)
    },

    // market
    addMarket: (value) => {
        return axios.post(Api.MARKET,
            {
                coinName: removeAfterLastSlash(value.coinName),
                quantity: value.quantity,
                currentPrice: value.currentPrice,
                totalPay: value.totalPay,
                type: value.type
            }
        )
    },
    getHistoryMarketOrders: (limit, page) => {
        return axios.get(Api.MARKETHISTORY, {
            params: {
                limit: limit,
                page: page,
                order: { createdAt: 'DESC' }
            }
        })
    },

    // future
    addFuture: (value) => {
        return axios.post(Api.FUTURE,
            {
                coinName: removeAfterLastSlash(value.coinName),
                quantity: value.quantity,               // 0
                entryPrice: value.entryPrice,           // 0
                expectPrice: value.expectPrice,         // 0
                lossStopPrice: value.lossStopPrice,     // 0
                type: value.type,                       // "limit|market"
                orderType: value.orderType,             // "long|short"
                leverage: value.leverage                // 0
            }
        )
    },
    getFutureMe: (limit, page, isEntry) => {
        return axios.get(Api.FUTUREME, {
            params: {
                limit: limit,
                page: page,
                order: { createdAt: 'DESC' },
                filter: { "isEntry": isEntry }
            }
        })
    },
    getHistoryFutureOrders: (limit, page) => {
        return axios.get(Api.FUTUREHISTORY, {
            params: {
                limit: limit,
                page: page,
                order: { createdAt: 'DESC' },
            }
        })
    },
    deleteFutureWithId: (id) => {
        return axios.delete(`${Api.DELETEFUTUREID}${id}`)
    },
    deleteFuture: (id) => {
        return axios.delete(`${Api.FUTURE}/${id}`);
    },
    
    closeFuture: (id, closingPrice) => {
        return axios.post(`v1/command/future/${id}/close`,
            {
                closingPrice: closingPrice
            }
        )
    },
    futureCommand: () => {
        return axios.get(Api.FUTURECOMMAND, {
            headers: {
                'x-lang': 'vi' // Set the language header
            },
            params: {
                limit: 1, // Item per page
                page: 1,  // Current page
                order: JSON.stringify({ createdAt: 'ASC' }) // Sort by field, serialized to a JSON string
            }
        })
    },
    updateFuture: (id, value) => {
        return axios.patch(`${Api.UPDATEFUTURE}${id}`,
            {
                quantity: value.quantity,
                entryPrice: value.entryPrice,
                expectPrice: value.expectPrice,
                lossStopPrice: value.lossStopPrice,
                orderType: value.orderType,        // long | short
                leverage: value.leverage
            }
        )
    },

    // stacking
    stacking: (value) => {
        return axios.post(Api.STACKING,
            {
                coinName: value.coinName,
                monthSaving: value.monthSaving,
                quantity: value.quantity
            }
        )
    },

    getStackingMe: (limit, page) => {
        return axios.get(Api.STACKINGME, {
            params: {
                limit: limit,
                page: page,
                order: { createdAt: 'DESC' }
            }
        })
    },

    getStackingRate: (coinName) => {
        return axios.get(Api.STACKINGRATE)
    },

    // wallet
    getWalletMe: (limit, page) => {
        return axios.get(Api.GETWALLETME, {
            params: {
                limit: limit,
                page: page,
                order: { createdAt: 'DESC' }
            }
        })
    },

    getWalletByCoin: (coinName) => {
        return axios.get(Api.GETWALLETME, {
            params: {
                limit: Number.MAX_SAFE_INTEGER,
                page: 1,
                filter: { "coinName": coinName },
                order: { createdAt: 'ASC' }
            }
        })
    },

    getHistoryMe: (limit, page) => {
        return axios.get(Api.GETHISTORYME, {
            params: {
                limit: limit,
                page: page,
                order: { createdAt: 'DESC' }
            }
        })
    },

    // verify-user
    verifyUser: (value) => {
        return axios.put(Api.VERIFYUSER,
            {
                dateOfBirth: value.dateOfBirth,
                faceID: value.faceID,
                address: value.address,
                frontCitizenID: value.frontCitizenID,
                backCitizenID: value.backCitizenID,
            }
        )
    }
};
export default service;