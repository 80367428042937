import React, { useState, useEffect } from 'react';
import './EditFuture.css';
import Box from '@mui/material/Box';
import Rhombus from '../../../../assets/images/icons/Rhombus.png'
import Slider from '@mui/material/Slider';
import { capitalizeFirstLetter } from '../../../../common/Common';
import axios from 'axios';
import service from '../../../../../service/Service';
import { toast } from 'react-toastify';
import Button from '../../../../components/Button/Button';

const LeverageView = ({ value, handleSliderChange }) => {

    const marks = [
        { value: 0 },
        { value: 5 },
        { value: 10 },
        { value: 15 },
        { value: 20 },
        { value: 25 },
        { value: 30 },
        { value: 35 },
        { value: 40 },
        { value: 45 },
        { value: 50 },
    ];

    const [valueText, setValueText] = useState(value)

    useEffect(() => {
        setValueText(value)
    }, [value]);

    const handleSliderOnChange = (event, newValue) => {
        handleSliderChange(newValue)
    };

    const formatValue = (value) => `${value}x`;

    return (
        <Box sx={{ width: '98%' }}>
            <Slider
                aria-label="Temperature"
                defaultValue={0}
                value={valueText}
                valueLabelFormat={formatValue}
                valueLabelDisplay="on"
                step={1}
                marks={marks}
                max={50}
                onChange={handleSliderOnChange}
                sx={{
                    '& .MuiSlider-thumb': {
                        '&:hover, &.Mui-focusVisible': {
                            boxShadow: 'none',
                        },
                        width: '20px',
                        height: '20px',
                        background: 'transparent',
                        backgroundImage: `url(${Rhombus})`, // Sử dụng hình ảnh thumb tùy chỉnh
                        backgroundSize: 'contain',
                        '&:active': {
                            boxShadow: 'none',
                        },
                    },
                    '& .MuiSlider-rail': {
                        backgroundColor: '#4a4a4a', // Màu của phần chưa kéo của Slider
                    },
                    '& .MuiSlider-track': {
                        backgroundColor: 'white', // Default color for track
                    },
                    '& .MuiSlider-mark': {
                        backgroundColor: '#4a4a4a', // Màu của cục mark
                        width: '8px', // Độ rộng của cục mark
                        height: '8px', // Độ cao của cục mark
                        borderRadius: '50%', // Hình dạng của cục mark (làm cho nó tròn
                    },
                }}
            />
        </Box>

    )

}
const EditFuture = ({ isOpen, onClose, onSave, dataTrade }) => {

    const [quantity, setQuantity] = useState(dataTrade.quantity);
    const [orderType, setOrderType] = useState(dataTrade.orderType);
    const [entryPrice, setEntryPrice] = useState(dataTrade.entryPrice);
    const [expectPrice, setExpectPrice] = useState(dataTrade.expectPrice);
    const [lossStopPrice, setLossStopPrice] = useState(dataTrade.lossStopPrice);
    const [leverage, setLeverage] = useState(dataTrade.leverage);

    useEffect(() => {
        setQuantity(dataTrade.quantity);
        setOrderType(dataTrade.orderType);
        setEntryPrice(dataTrade.entryPrice);
        setExpectPrice(dataTrade.expectPrice);
        setLossStopPrice(dataTrade.lossStopPrice);
        setLeverage(dataTrade.leverage);
    }, [dataTrade]);

    const handleSave = async () => {
        let value = {
            quantity: parseFloat(quantity),
            entryPrice: parseFloat(entryPrice),
            expectPrice: parseFloat(expectPrice),
            lossStopPrice: parseFloat(lossStopPrice),
            orderType: orderType,
            leverage: parseFloat(leverage)
        }
        try {
            await service.updateFuture(dataTrade.id, value)
            onClose()
            toast.success("Update successfully", {
                position: 'bottom-right'
            })
        } catch (error) {
            toast.error(`${error}`, {
                position: 'bottom-right'
            })
        }
        ;
    };

    const changeSlider = (newValue) => {
        setLeverage(newValue)
    }

    const handleInputChange = (setter) => (e) => {
        const value = e.target.value;
        // Kiểm tra nếu giá trị chỉ chứa các ký tự số
        if (/^\d*$/.test(value)) {
            setter(value);
        }
    };

    if (!isOpen) return null;

    return (
        <div className="edit_future_view">
            <div className='container'>
                <h3 style={{ color: 'white' }}>Update your trade</h3>
                <div className='between-space'>
                    <p>Symbol </p>
                    <p className='pBold' style={{ color: '#52C08F' }}>{dataTrade.coinName}</p>
                </div>
                <div className='between-space'>
                    <p>Type</p>
                    <p className='pBold'>{capitalizeFirstLetter(dataTrade.type)} </p>
                </div>
                <div className='between-space'>
                    <p>Quantity</p>
                    <p className='pBold'>{quantity} </p>
                </div>
                <div className='between-space'>
                    <p>Order type</p>
                    <p className='pBold'>{orderType == 'long' ? 'Long' : 'Short'} </p>
                    {/* <select
                        className='input_enable_edit'
                        style={{ width: '100px' }}
                        value={orderType}
                        onChange={(e) => setOrderType(e.target.value)}
                    >
                        <option value="long">Long</option>
                        <option value="short">Short</option>
                    </select> */}
                </div>
                <div className='between-space'>
                    <p>Entry Price</p>
                     <p className='pBold'>{entryPrice} </p>
                </div>
                <div className='between-space'>
                    <p>Expect Price</p>
                    <input
                        className='input_enable_edit'
                        type="text"
                        value={expectPrice}
                        onChange={handleInputChange(setExpectPrice)}
                    />
                </div>
                <div className='between-space'>
                    <p>Loss stop Price</p>
                    <input
                        className='input_enable_edit'
                        type="text"
                        value={lossStopPrice}
                        onChange={handleInputChange(setLossStopPrice)}
                    />
                </div>
                {/* <div className='between-space'>
                    <p>Leverage</p>
                    <p className='pBold'>{leverage}x</p>
                </div>

                <div className='slider'>
                    <LeverageView value={leverage} handleSliderChange={changeSlider} />
                </div> */}
            </div>
            <div className="edit-future">
                <button onClick={onClose}>Cancel</button>
                <Button
                    onClick={() => { handleSave() }}
                    title="Confirm"
                    size="small"
                    isGradient={true}
                />
            </div>
        </div>
    );
};

export default EditFuture;
