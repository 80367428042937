import Select, { components } from 'react-select';
import { useState } from 'react';

export const CustomOption = (props) => {
    return (
        <components.Option {...props}>
            {/* Bạn có thể tùy chỉnh phần tử hiển thị ở đây */}
            <div style={{ display: 'flex', alignItems: 'center', backgroundColor: '' }}>
                <img src={props.data.image} alt="" style={{ width: 30, height: 30, marginRight: 10, borderRadius: 15 }} />
                <span>{props.data.label}</span>
            </div>
        </components.Option>
    );
};

export const ChooseTime = (props) => {
    return (
        <components.Option {...props}>
            <div>
                <p style={{ fontWeight: '600', fontSize: '16px' }}>{props.data.label}</p>
                <div style={{ display: 'flex' }}>
                    <p style={{ fontSize: '15px' }}>Provisional Profit: &nbsp;</p>
                    <p style={{ color: '#52C08F', fontWeight: '600', fontSize: '15px' }}>{props.data.profit}%/year</p>
                </div>

            </div>
        </components.Option>
    );
}

const SelectOptions = ({ value, onSelect, options, view }) => {

    const handleChange = (selectedOption) => {
        onSelect(selectedOption); // Gọi hàm callback khi có sự thay đổi
    };
    
    const formatOptionLabel = ({ image, label }) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {/* <img
            src={image}
            alt={label}
            style={{ width: 20, height: 20, borderRadius: '50%', marginRight: 10 }}
          /> */}
          <span>{label}</span>
        </div>
      );

    return (
        <div>
            <Select
                formatOptionLabel={formatOptionLabel}
                value={value}
                onChange={handleChange}
                options={options}
                isSearchable={true} // Cho phép tìm kiếm
                placeholder="Select an option..." // Văn bản hiển thị khi chưa có lựa chọn
                components={{ Option: view }} // Sử dụng CustomOption để tùy chỉnh hiển thị
                styles={{
                    menuList: (provided) => ({
                        ...provided,

                        // maxHeight: 'none', // Tắt giới hạn chiều cao
                        overflowY: 'auto', // Bật scroll khi nội dung vượt quá chiều cao
                        '&::-webkit-scrollbar': {
                            display: 'none', // Ẩn thanh scroll bar
                        },
                        overflowX: 'none'
                    }),
                    menu: (provided) => ({
                        ...provided,
                        backgroundColor: '#0e0e0e',
                        borderRadius: '4px',
                        marginTop: 0,
                        borderColor: '#ccc', // Màu viền của menu
                        borderWidth: '1px', // Độ dày của viền
                        borderStyle: 'solid',  // Để menu hiển thị ngay dưới ô input
                    }),
                    control: (provided) => ({
                        ...provided,
                        borderRadius: '4px', // Bo tròn góc cho ô input
                        borderColor: '#ccc', // Màu viền của ô input
                        minHeight: '40px', // Chiều cao tối thiểu của ô input
                        backgroundColor: '#0e0e0e',
                        color: '#fff'
                    }),
                    option: (provided, state) => ({
                        ...provided,
                        color: state.isSelected ? 'white' : 'white', // Màu chữ của option
                        backgroundColor: state.isSelected ? '#2b3139' : '#1e2328', // Màu nền khi option được chọn
                        borderRadius: '4px',
                        borderColor: '#fd7fa2',
                        margin: '5px 5px 0px',
                        width: '97%'
                        // padding: '8px 12px'
                    }),
                    singleValue: (provided) => ({
                        ...provided,
                        color: '#FFF' // Màu chữ của giá trị đã chọn
                    }),
                    input: (provided) => ({
                        ...provided,
                        color: '#FFF', // Text color of input
                    }),
                }}
            />
        </div>
    );
}

export default SelectOptions;