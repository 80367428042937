import styles from './BuyLimitView.module.scss'
import { useState } from 'react';
import SliderPrice from '../../components/Chart/SliderPrice';
import TextFieldPrice from '../TextField/TextFieldPrice';

const SellStopLimitView = ({value}) => {

  const [isFocusedPrice, setIsFocusedPrice] = useState(false);
  const [isFocusedAmount, setIsFocusedAmount] = useState(false);
  const [isFocusedTotal, setIsFocusedTotal] = useState(false);
  const [percentValue, setPercentValue] = useState(0)

  const handleSlider = (data) => {
    setPercentValue(data)
  }

  return (
    <div className={styles.container}>
      <TextFieldPrice title='Stop (USTD)' placeholder='Enter' type="number"/>
      <TextFieldPrice title={`${value} (USTD)`} placeholder='Enter' type="number"/>
      <TextFieldPrice title='Amount' placeholder='Enter' type="number"/>
      <SliderPrice handleSliderChange={handleSlider} />
      <TextFieldPrice title='Total' placeholder='Enter' type="number"/>
    </div>
  );
}

export default SellStopLimitView;