export const formatNumber = (num) => {
    // Đảm bảo rằng số đầu vào là một số thực
    const parsedNumber = parseFloat(num);

    // Kiểm tra nếu đầu vào không hợp lệ
    if (isNaN(parsedNumber)) {
        return "0.00";
    }

    // Format số với 2 chữ số thập phân và ngăn cách bằng dấu phẩy
    return parsedNumber.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 12, useGrouping: false });
};