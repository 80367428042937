import React from "react";
// import styles from "./Button.module.scss";
// import styles from "../../../css/components/Button/Button.module.scss"
import styles from "../../../../src/css/components/Button/Button.module.scss"

const Button = ({
  title,
  size,
  style,
  className,
  onClick,
  isGradient,
  isDisabled,
}) => {
  const sizeClass =
    size === "large"
      ? styles.btn_large
      : size === "small"
      ? styles.btn_small
      : "";

  const gradientClass = isGradient ? styles.btn__grad : "";

  return (
    <button
      style={{
        opacity: isDisabled ? "0.7" : "1",
        ...style,
      }}
      disabled={isDisabled}
      className={`${className} ${styles.button} ${sizeClass} ${gradientClass}`}
      onClick={onClick}
    >
      {title}
    </button>
  );
};

export default Button;

Button.defaultProps = {
  size: "",
  style: {},
  className: "",
  onClick: () => { },
  isGradient: false,
  isDisabled: false,
};
