import React, { useState, useEffect, useRef } from "react";
import styles from "../../../css/components/Navbar/Navbar.module.scss";
import { menuItems } from "../../utils/constants/MenuItems.js";
import Button from "../Button/Button.jsx";
import BaseLogo from "../../../js/assets/images/logos/BaseLogo.svg";
import Arrow from "../../../js/assets/images/icons/Arrow.svg";
import logo from '../../../logo.svg'
import RaffleIcon from "../../../js/assets/images/icons/raffle_icon.png";
import Telegram from "../../../js/assets/images/icons/Telegram.svg"
import { Link, useNavigate, useLocation } from "react-router-dom";
import { HiMenuAlt1 } from "react-icons/hi";
import Login from "../Login/Login";
import { auth, db } from "./../../../firebase/firebase"
import { doc, getDoc } from "firebase/firestore";
import "react-toastify/dist/ReactToastify.css"
import { ToastContainer, toast } from "react-toastify";
import UserImage from '../../assets/images/icons/User.png'
import logout from '../../assets/images/icons/Logout.png'
import service from "../../../service/Service";
import { ReactComponent as Icon } from './notification_icon.svg';

const Navbar = ({ onLoginClick, isLogined }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [scrolled, setScrolled] = useState(false);
  const [isDropdownOpen, setDropdownOpen] = useState(-1);
  const dropdownRef = useRef([]);
  const [isMobileDropdownOpen, setMobileDropdownOpen] = useState(-1);

  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  const mobileMenuRef = useRef(); // Referansı oluştur
  const [showPopup, setShowPopup] = useState(false);
  const [userDetails, setUserDetails] = useState();
  const [logined, setLogined] = useState(isLogined)

  const redirectToDAO = () => {
    navigate("/dao");
  };

  const redirectToChart = () => {
    navigate("/chart");
  };

  const [isOpenProfile, setIsOpenProfile] = useState(false);

  const toggleDropdownProfile = () => setIsOpenProfile(!isOpenProfile);

  if (localStorage.getItem('accessToken') == ''){
    if (location.pathname !== "/") {
      // window.location.href = '/'
    }
  }

  useEffect(() => {
    // Mỗi khi isLogined thay đổi, cập nhật giá trị mới nhất vào logined
    setLogined(isLogined);
    getUserMe()
  }, [isLogined]);

  const getUserMe = async () => {
    try {
        let res = await service.userMe()
        setUserDetails(res.data.data)
        console.log('res', res)
    } catch (error) {
        // toast.error(`${error}`, {
        //     position: 'bottom-right'
        // })
    }
}

  async function handleLogout() {
    try {
      await service.logout()
      setLogined(false)
      isLogined = false
      setIsOpenProfile(false)
      localStorage.setItem('accessToken', "");
      localStorage.setItem('refreshToken', "");
      if (location.pathname !== "/") {
        navigate("/");
      }
      onLoginClick()
    } catch (error) {
      toast.error(`${error}`, {
        position: 'bottom-right'
      })
    }
  }

  const goToProfile = () => {
    window.open("/profile", "_blank")
  }

  // useEffect(() => {
  //   getUserMe()
  // }, []);

  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 10) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    const checkIfClickedOutside = (e) => {
      if (
        isDropdownOpen !== -1 &&
        dropdownRef.current[isDropdownOpen] &&
        !dropdownRef.current[isDropdownOpen].contains(e.target)
      ) {
        setDropdownOpen(-1);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isDropdownOpen]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  });

  let navbarClasses = [styles.navbar];
  if (scrolled) {
    navbarClasses.push(styles.scrolled);
  }

  const scrollToContact = () => {
    const element = document.getElementById("contact");
    const offset = 100;
    const bodyRect = document.body.getBoundingClientRect().top;
    const elementRect = element.getBoundingClientRect().top;
    const elementPosition = elementRect - bodyRect;
    const offsetPosition = elementPosition - offset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        mobileMenuRef.current &&
        !mobileMenuRef.current.contains(event.target)
      ) {
        setMobileMenuOpen(false);
      }
    };

    // Dışarı tıklama işlemi olduğunda handleClickOutside fonksiyonunu çalıştır
    document.addEventListener("mousedown", handleClickOutside);

    // Component temizlendiğinde (unmount) event listener'ı kaldır
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []); // Boş bir bağımlılık dizisi ile, bu useEffect sadece ilk render'da çalışır ve event listener'ı temizler

  const socialList = [
    {
      name: "Telegram",
      link: "https://t.me/HyperGPTai ",
      icon: Telegram,
    },
    {
      name: "Telegram",
      link: "https://t.me/hypergpt_news  ",
      icon: Telegram,
    },
    {
      name: "Discord",
      link: "https://discord.com/invite/hypergpt ",
      icon: Telegram,
    },
    {
      name: "Twitter",
      link: "https://twitter.com/hypergpt ",
      icon: Telegram,
    },
  ];

  const rightNavbarNotLogin = () => {
    return (
      <>
        <span className={styles.navbar__right__icons}>
          {socialList.map((item, index) => {
            return (
              <a
                href={item.link}
                target="_blank"
                rel="noreferrer"
                key={index}
              >
                <img src={item.icon} alt="" />
              </a>
            );
          })}
        </span>

        <Button
          onClick={() => {
            onLoginClick()
          }}
          title="Sign in"
          size="large"
          isGradient={true}
          key={1}
        />
      </>
    )
  }

  const rightNavbarLogin = () => {

    return (
      <div className={styles.right_navbar_login}>
        <div className={styles.name}>
          {/* <h3> {userDetails.name} {userDetails.lastName}</h3> */}
          <span>{userDetails.email}</span>
        </div>
        <div className={styles.info_logined}>
          <button onClick={toggleDropdownProfile} className={styles.profile_button}>
            <img src={UserImage}></img>
          </button>
          {isOpenProfile && (
            <div className={styles.dropdown_profile}>
              <div className={styles.dropdown_item}>
                {/* <h3> {userDetails.name} {userDetails.lastName}</h3>
                <span>{userDetails.email}</span> */}
                <button className={styles.logout} onClick={() => { handleLogout() }}>
                  <span>Log out </span>
                  <img src={logout}></img>
                </button>
                <button className={styles.logout} onClick={() => goToProfile()}>
                  <span> Profile </span>
                </button>
              </div>
            </div>
          )}
        </div>
        <div style={{marginLeft: '60%'}}>
          <a href='/notification'>
            <Icon style={{width: '30px', height: '30px'}}></Icon>
          </a>
        </div>
      </div>
    )
  }

  return (
    <nav className={`${styles.navbar} ${scrolled ? styles.scrolled : ""}`}>
      <div className={styles.navbar__container}>
        <div className={styles.navbar__left}>
          <Link to={"/"} className={styles.navbar__left__logo}>
            <img src={BaseLogo} alt="" />
          </Link>
          <div className={styles.navbar__left__items}>
            {menuItems.map((item, index) => {
              return (
                <span
                  className={styles.navbar__left__item}
                  onMouseEnter={() => setDropdownOpen(index)}
                  ref={(el) => (dropdownRef.current[index] = el)}
                  onClick={() => {
                    if (item.name === "Pricing") {
                      window.open("/chart", "_blank")
                    }
                    if (item.name === "HyperDAO") {
                      redirectToDAO();
                    } else if (item.name.toLowerCase() === "contact") {
                      scrollToContact();
                    } else if (item.name.toLowerCase()) {
                      navigate(item.path);
                    }
                  }}
                  key={index} // Add a unique key for each item
                >
                  {item.name}
                  &nbsp;
                  {item.isSubmenu && <img src={Arrow} alt="" />}
                  {isDropdownOpen === index && item.isSubmenu && (
                    <div
                      onMouseLeave={() => setDropdownOpen(-1)}
                      className={styles.navbar__left__item__dropdown}
                    >
                      {item?.submenuItems?.map((submenuItem, subIndex) => {
                        return item === "$HGPT" ||
                          item === "Learn" ||
                          item === "Community" ? (
                          <Link
                            className={
                              styles.navbar__left__item__dropdown__item__link
                            }
                            to={submenuItem.path}
                            key={subIndex} // Add a unique key for each submenu item
                          >
                            <span
                              className={
                                styles.navbar__left__item__dropdown__item
                              }
                            >
                              {submenuItem.name}
                            </span>
                          </Link>
                        ) : (
                          <a
                            rel="noreferrer"
                            target="_blank"
                            href={submenuItem.path}
                            className={
                              styles.navbar__left__item__dropdown__item__link
                            }
                            key={subIndex} // Add a unique key for each submenu item
                          >
                            <span
                              className={
                                styles.navbar__left__item__dropdown__item
                              }
                            >
                              {submenuItem.name}
                            </span>
                          </a>
                        );
                      })}
                    </div>
                  )}
                </span>
              );
            })}
          </div>
          <a
            style={{
              position: "relative",
              right: "25px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            rel="noreferrer"
            target="_blank"
            href="https://store.hypergpt.ai/HyperRaffle"
          >
            <img
              style={{
                width: "50px",
                height: "50px",
              }}
              src={RaffleIcon}
              alt=""
            />
          </a>
        </div>
        <div className={styles.navbar__right}>
          {(userDetails != null) ? rightNavbarLogin() : rightNavbarNotLogin()}
          <span
            onClick={() => setMobileMenuOpen(!isMobileMenuOpen)}
            className={styles.navbar__mobile__menu}
          >
            <HiMenuAlt1 />
          </span>

          {isMobileMenuOpen && (
            <div
              className={styles.navbar__mobile__menu__items}
              ref={mobileMenuRef}
            >
              {menuItems.map((item, index) => {
                return (
                  <div key={index}>
                    <span
                      className={styles.navbar__mobile__menu__item}
                      onClick={() => {
                        if (item.name.toLowerCase() === "contact") {
                          scrollToContact();
                        } else {
                          setMobileDropdownOpen(
                            isMobileDropdownOpen === index ? -1 : index
                          );
                        }
                      }}
                    >
                      <div>
                        {item.name === "HyperDAO" ? (
                          <span onClick={redirectToDAO}>{item.name}</span>
                        ) : (
                          <span>{item.name}</span>
                        )}
                        {item.isSubmenu && (
                          <span
                            className={styles.navbar__mobile__menu__item__arrow}
                          >
                            {item.isSubmenu && <img src={logo} alt="" />}
                          </span>
                        )}
                      </div>
                      {isMobileDropdownOpen === index && item.submenuItems && (
                        <span
                          className={
                            styles.navbar__mobile__menu__item__dropdown
                          }
                        >
                          {item.submenuItems.map((submenuItem, subIndex) => {
                            return (
                              <Link
                                className={
                                  styles.navbar__left__item__dropdown__item__link
                                }
                                to={submenuItem.path}
                                key={subIndex}
                              >
                                <span
                                  className={
                                    styles.navbar__left__item__dropdown__item
                                  }
                                >
                                  {submenuItem.name}
                                </span>
                              </Link>
                            );
                          })}
                        </span>
                      )}
                    </span>
                  </div>
                );
              })}

              <Button title="Launch App" size="large" isGradient={true} />
              <span className={styles.navbar__right__icons}>
                {socialList.map((item, index) => {
                  return (
                    <a
                      href={item.link}
                      target="_blank"
                      rel="noreferrer"
                      key={index}
                    >
                      <img src={item.icon} alt="" />
                    </a>
                  );
                })}
              </span>
            </div>
          )}
        </div>
      </div>

      {showPopup && (
        <Login />
      )}
    </nav>
  );
};

export default Navbar;

