import { Routes, Route, BrowserRouter } from "react-router-dom";
import Home from "./pages/Home/Home";
import Store from "./pages/HyperStore/HyperStore";
import SDK from "./pages/HyperSDK/HyperSDK";
import NFT from "./pages/HyperNFT/HyperNFT";
import About from "./pages/About/About";
import Dao from "../js/pages/Dao/Dao"
import Layout from "./components/Layout/Layout";
import { ToastContainer } from "react-toastify";
import { NotificationProvider } from "./context/NotificationContext";
import Notification from "./components/Notification/Notification";
import Ambassador from "./pages/Ambassador/Ambassador";
import Pricing from "./pages/Pricing/Pricing";
import ContainerChartView from "./chart/ContainerChartView";
import Profile from "./pages/Profile/Profile";
import Login from "./pages/Login/Login";
import Admin from "./pages/Admin/Admin";
import './App.css';
import UploadMultipleImage from "./pages/Profile/UploadImage/UploadMultipleImage";
import TermService from "./policy/TermService/TermService";
import { PrivacyPolicy } from "./policy/PrivacyPolicy/PrivacyPolicy";import BuyCrypto from "./pages/Profile/BuyCrypto";
import Notification1 from "./pages/Admin/Notification1";
import HistoryTrade from "./pages/Profile/HistoryTrade/HistoryTrade";
import { RiskWarning } from "./policy/RiskWarning/RiskWarning";

function App() {
  return (
  
    <NotificationProvider>
      {" "}
      <div className="">
        <BrowserRouter>
          <Layout>
            <Routes>
              <Route>
                <Route path="/" element={<Home />} />
                <Route path="/hyperstore" element={<Store />} />
                <Route path="/hypersdk" element={<SDK />} />
                <Route path="/hypernft" element={<NFT />} />
                <Route path="/dao" element={<Dao />} />
                <Route path="/ambassador-program" element={<Ambassador />} />
                <Route path="/about" element={<About />} />
                <Route path="/pricing" element={<Pricing />} />
                <Route path= "/chart" element={<ContainerChartView />} />
                <Route path="/profile" element={<Profile/>} />
                <Route path="/profile/verify-user" element={<UploadMultipleImage/>} />
                <Route path="/login" element={<Login/>} />
                <Route path="/admin" element={<Admin/>} />
                <Route path="/term-service" element={<TermService/>} />
                <Route path="/privacy-policy" element={<PrivacyPolicy/>} />
                <Route path="/risk-warning" element={<RiskWarning/>} />
                <Route path="/action/:type" element={<BuyCrypto />} />
                <Route path="/notification" element={<Notification1 />} />
                <Route path="/profile/trade-history" element={<HistoryTrade />} />
              </Route>
            </Routes>
              <ToastContainer zIndex={100000000} autoClose={5000}/>
          </Layout>
          
        </BrowserRouter>
      </div>
      <Notification />
    </NotificationProvider>
  );
}

export default App;
