const initialState = {
    priceSymbol: 0,
    nameSymbol: "",
    avlbUSDT: 0
};

export default (state = initialState, action) => {
    switch (action.type) {
        case 'PriceSYMBOL':
            return {
                ...state,
                priceSymbol: action.payload,
            };
        case 'NameSYMBOL':
            return {
                ...state,
                nameSymbol: action.payload,
            };
        case 'AvailableUSDT':
            return {
                ...state,
                avlbUSDT: action.payload,
            }
        default:
            return state;
    }
};
