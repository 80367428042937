import { useNavigate } from 'react-router-dom';
import './Login.scss'

const Login = () => {
  const navigate = useNavigate();

  const goAdmin = (e) => {
    e.preventDefault();
    navigate('/admin');
  }

  return (
    <div className="login-form">
      <form className="login-form-group" onSubmit={goAdmin}>
        <div className="form-element">
            <label>Usersname</label>
            <input
                type="text"
                name="fullName"
            />
            <label>Password</label>
            <input
                type='password'
                name="password"
            />
            <div className="login-action">
              <button type="button" className="cancel-btn">Cancel</button>
              <button type="submit" className="save-btn">Login</button>
             </div>
        </div>
      </form>
    </div>
  );
};

export default Login;
