// src/components/Sidebar.js
import './Sidebar.scss';

const Sidebar = ({setOption}) => {
  return (
    <div className="sidebar">
      <ul>
        <li><a href="#home" onClick={() => setOption(1)}>Dashboard</a></li>
        <li><a href="#notification" onClick={() => setOption(2)}>Notifications</a></li>
        <li><a href="#about">About</a></li>
        <li><a href="#contact">Contact</a></li>
      </ul>
    </div>
  );
};

export default Sidebar;
