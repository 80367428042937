import styles from './BuyLimitView.module.scss'
import { useState, useEffect } from 'react';
import SliderPrice from '../../components/Chart/SliderPrice';
import TextFieldPrice from '../TextField/TextFieldPrice';
import Button from '../../components/Button/Button';
import { useSelector } from 'react-redux';
import service from '../../../service/Service';
import { formatNumber } from '../../common/NumberFormat';
import { toast } from "react-toastify";
import { removeAfterLastSlash, roundDown, roundUp } from '../../common/Common';

const SellLimitView = () => {

  const [amount, setAmount] = useState(0)
  const [percentValue, setPercentValue] = useState(0)
  const nameSymbol = useSelector((state) => state.trade.nameSymbol)
  const [titleButton, setTitleButton] = useState(`Sell ${nameSymbol}`)
  const priceSymbol = useSelector((state) => state.trade.priceSymbol);
  const [price, setPrice] = useState(priceSymbol)
  const [expectPrice, setExpectPrice] = useState(0)
  const [lossStopPrice, setLossStopPrice] = useState(0)
  const [avlbToSell, setAvlbToSell] = useState(0)
  const [total, setTotal] = useState(0)
  const [loading, setLoading] = useState(true)
  const [disableButton, setDisableButton] = useState(true)

  useEffect(() => {
    setTitleButton(`Sell ${nameSymbol}`)
    getAvlbToSell()
  }, [nameSymbol]);

  useEffect(() => {
    setPrice(formatNumber(priceSymbol))
  }, [priceSymbol]);

  useEffect(() => {
    if (amount <= 0 || amount == '' || total <= 0 || total == '' || expectPrice <= 0 || expectPrice == '' || lossStopPrice <= 0 || lossStopPrice === '' || amount > avlbToSell) {
      setDisableButton(true)
    } else {
      setDisableButton(false)
    }
  }, [amount, total, expectPrice, lossStopPrice]);

  useEffect(()=> {
    setTotal(roundUp(expectPrice * amount))
  }, [expectPrice])
  const getAmount = (data) => {
    setAmount(data)
    let totalTemp = parseFloat(expectPrice) * parseFloat(data)
    setTotal(roundUp(totalTemp))
    setPercentValue(0)
  }

  const handleSlider = (data) => {
    setPercentValue(data)
    let amount = data * avlbToSell / 100
    setAmount(roundDown(amount))
    setTotal(roundUp(expectPrice*amount))
  }

  const getExpectPrice = (data) => {
    setExpectPrice(data)
  }

  const getLossStopPrice = (data) => {
    setLossStopPrice(data)
  }

  const handleConfirm = async () => {
    if (amount == 0 || lossStopPrice == 0 || expectPrice == 0) {
      toast.warning(`Please fill out all the fields`, {
        position: 'bottom-right'
      })
    } else {
      let value = {
        coinName: nameSymbol,
        totalPay: parseFloat(total),
        quantity: parseFloat(amount),
        expectPrice: parseFloat(expectPrice),
        type: "sell",
        lossStopPrice: parseFloat(lossStopPrice)
      }
      try {
        let res = await service.addLimit(value)
        toast.success(`Sell ${nameSymbol} successfully`, {
          position: 'bottom-right'
        })
      } catch (error) {
        console.log('catch', error)
        toast.error(`${error}`, {
          position: 'bottom-right'
        })
      }
    }

  }

  const getAvlbToSell = async () => {
    try {
      let res = await service.getWalletByCoin(removeAfterLastSlash(nameSymbol))
      setAvlbToSell(res.data.data[0].quantity)
      setLoading(false)
    } catch (err) {
      setAvlbToSell(0)
      setLoading(true)
    }
  }

  const mainView = () => {
    return (
      <>
        <TextFieldPrice title='Price' placeholder='Enter' isReadOnly={true} value={price} type="text" />
        <TextFieldPrice title='Expect price' placeholder='Enter' value={expectPrice} onChange={getExpectPrice} type="text" max={7} />
        <TextFieldPrice title='Amount' placeholder='Enter' value={amount} onChange={getAmount} type="text" unit={removeAfterLastSlash(nameSymbol)} />
        <SliderPrice value={percentValue} handleSliderChange={handleSlider} />
        <div className={styles.availablePrice}>
          <p>Avlb</p>
          <p> <b>{avlbToSell}</b> {removeAfterLastSlash(nameSymbol)}</p>
        </div>
        
        <TextFieldPrice title='Loss stop price' placeholder='Enter' value={lossStopPrice} onChange={getLossStopPrice} type="text" max={7}/>
        <TextFieldPrice title='Total' placeholder='Enter' isReadOnly={true} value={total} type="text" />

        <div style={{ marginTop: '30px' }}>
          <Button
            onClick={() => {
              handleConfirm()
            }}
            isDisabled={disableButton}
            title={titleButton}
            size="large"
            isGradient={true}
            key={1}
            style={{ width: '100%' }}
          />
        </div>
      </>
    )
  }

  const nonSell = () => {
    return (
      <p style={{ color: 'white' }}>Nothing to sell</p>
    )
  }

  return (
    <div className={styles.container}>
      {loading ? nonSell() : mainView()}
    </div>
  );
}

export default SellLimitView;