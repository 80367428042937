import { useEffect, useState } from "react"
import service from "../../../service/Service";
import { Modal } from "@mui/material";
import './Notification1.scss'

const Notification1 = () => {
    const [notifications, setNotifications] = useState([])
    const [currentPage, setCurrentPage] = useState(1);
    const [limit, setLimit] = useState(15);
    const [total, setTotal] = useState(0);
    const [showDetail, setShowDetail] = useState(false)
    const [detailNotification, setDetailNotification] = useState([])

    const getNotificationPerPage = async () => {
        try {
            const response = await service.getAllNotifications(limit, currentPage)
            const data = response.data.data;
            console.log(currentPage);
            console.log(data);
            const totalNotifications = response.data.pagination.total;

            setNotifications(data);
            setTotal(totalNotifications);
        } catch (error) {
            console.error("Failed to fetch notifications for the current page:", error);
        }
    };

    // const getAllNotifications = async () => {
    //     try {
    //         const response = await service.getAllNotifications(Number.MAX_SAFE_INTEGER, 1);
    //         setAllUsers(response.data.data);
    //         setAllUsersFetched(true);
    //     } catch (error) {
    //         console.error("Failed to fetch all users:", error);
    //     }
    // };

    const handleChangePage = (page) => {
        setCurrentPage(page);
    };

    const openDetail = async (id) => {
        const response = await service.getNotificationById(id)
        setDetailNotification(response.data.data)
        setShowDetail(true)
    }

    const closeDetail = () => {
        setShowDetail(false)
    }

    useEffect(() => {
        getNotificationPerPage(limit, currentPage)
    }, [currentPage, limit]);

    return (
        <div className="dashboard">
            <table>
                <thead>
                    <tr>
                        <th>Notification</th>
                        <th className='details'>Details</th>
                    </tr>
                </thead>
                <tbody>
                    {notifications.map((value, index) => (
                        <tr key={index}>
                            <td>{value.metaData.body}</td>
                            <td><button onClick={() => openDetail(value.id)}>Detail</button></td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <div className="pagination">
                {Array.from({ length: Math.ceil(total / limit) }, (_, index) => (
                    <button
                        key={index}
                        onClick={() => handleChangePage(index + 1)}
                        className={currentPage === index + 1 ? 'active' : ''}
                    >
                        {index + 1}
                    </button>
                ))}
            </div>
            <Modal onClose={closeDetail}
                   open={showDetail} 
                   className="notification-detail">
                <div>
                    <h1>Notification detail</h1>
                    <br></br>
                    <span className="notification"><b>Notification content: </b><i>{detailNotification?.metaData?.body || ""}</i></span>
                    <span className="notification"><b>Created date: </b><i>{(new Date(detailNotification?.createdAt)).toLocaleString('en-US') || ""}</i></span>
                    <span className="notification"><b>Coin amount: </b><i>{detailNotification?.metaData?.amount || detailNotification?.metaData?.quantity}</i></span>
                    <span className="notification"><b>Coin name: </b><i>{detailNotification?.metaData?.coinName || ""}</i></span>
                    <span className="notification"><b>
                        {detailNotification?.metaData?.action === 'increase'
                        || detailNotification?.metaData?.action === 'decrease' ? 'Remaining balance: ' : 'Type: '
                        }</b><i>{detailNotification?.metaData?.remainBalance || detailNotification?.metaData?.type}</i></span>
                </div>
            </Modal>
        </div>
    )
}

export default Notification1
