export const sdkContent = {
    topContent: {
        title: "**Seamlessly Access All AI APIs from a Single Account!**",
        desc: "HyperSDK is a powerful tool that allows developers to easily integrate AI solutions into their decentralized applications (dApps) through a single API. ",
    },
    stepContent: [{
            id: 1,
            title: "EASY INTEGRATION",
            desc: "Simplify AI integration with HyperSDK's user-friendly API, reducing development time and costs while eliminating the need for deep AI expertise.",
        },
        {
            id: 2,
            title: "Cost-effective",
            desc: "Save money with HyperSDK by reducing development costs and offering cost-efficient AI-powered experiences for end-users. ",
        },
        {
            id: 3,
            title: "AI-Powered dApps",
            desc: "Create personalized and efficient experiences using HyperSDK to develop AI-powered dApps with features like instant customer support and smart recommendations.",
        },
        {
            id: 4,
            title: "Enhanced Security",
            desc: "Protect sensitive user data with HyperSDK's robust security features, including blockchain-based data storage and advanced encryption techniques for secure transmission.",
        },
    ],
    bottomContent: {
        lightTitle: "Revolutionize Your dApps with HyperSDK!",
        title: "**Unlock AI's Potential** in Your dApps with HyperSDK!",
        desc: "HyperSDK is a powerful tool that can help developers easily integrate AI solutions into their dApps, while also providing end-users with more personalized and efficient experiences. With its cost-effectiveness, compatibility with both Web2 and Web3 technologies, and advanced security features, HyperSDK is a valuable tool for developers looking to create innovative and AI-powered dApps.",
    },
};