// // Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// import {getAuth} from "firebase/auth";
// import {getFirestore} from "firebase/firestore";
// // TODO: Add SDKs for Firebase products that you want to use
// // https://firebase.google.com/docs/web/setup#available-libraries

// // Your web app's Firebase configuration
// const firebaseConfig = {
//   apiKey: "AIzaSyDrB9FupDgjjVk4_guLC24ydG3retE3bME",
//   authDomain: "login-auth-4736e.firebaseapp.com",
//   projectId: "login-auth-4736e",
//   storageBucket: "login-auth-4736e.appspot.com",
//   messagingSenderId: "10562914305",
//   appId: "1:10562914305:web:2cff37be4fa9ccf0a29800"
// };

// // Initialize Firebase
// const app = initializeApp(firebaseConfig);

// export const auth=getAuth();
// export const db=getFirestore(app);
// export default app;

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {getAuth, GoogleAuthProvider, signInWithPopup} from "firebase/auth";
import {getFirestore} from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCBGcdadfAE_6hMpZrhacetwwwNnu0jAYE",
  authDomain: "test-f587c.firebaseapp.com",
  projectId: "test-f587c",
  storageBucket: "test-f587c.appspot.com",
  messagingSenderId: "143670171027",
  appId: "1:143670171027:web:5b224911125c6718d8c466",
  measurementId: "G-DTQGC2WSQ3"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth=getAuth();
export const provider = new GoogleAuthProvider();
provider.setCustomParameters({   
  prompt : "select_account "
});
export const signInWithGooglePopup = () => signInWithPopup(auth, provider);


export const db=getFirestore(app);
export default app;