import axios from "axios";

export const instance = axios.create({
    baseURL: "https://api.hypergpt.ai",
    headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST",
        "Access-Control-Allow-Headers": "Content-Type, Authorization, X-Requested-With",
    },
});