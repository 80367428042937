// import {
//     Robot
// } from "../../assets";

import Robot from "../../../js/assets/images/Robot.svg"

export const robotBanner = {
    titleFirst: "Transform Your Business and",
    titleSecond: "Delight Your Customers with",
    titleThird: "HyperGPT's Advanced Solutions",
    descFirst: "At HyperGPT, we're committed to delivering AI solutions that enhance your business's",
    descSecond: " performance and improve your customer experience. Our team of experts is dedicated",
    descThird: " to delivering cutting-edge technology and exceptional customer service, ensuring you",
    descFourth: "get the most out of our platform.",
    buttonText: "Launch App",
    img: Robot,
};