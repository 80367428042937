import React, { useEffect, useState } from 'react';
import './WalletInfo.css';
import eyeOpen from './../../assets/images/icons/wallet/EyeOpen.png'; // Thay thế bằng đường dẫn thực tế đến hình ảnh icon mắt mở
import eyeClosed from './../../assets/images/icons/wallet/EyeClose.png';
import deposit from './../../assets/images/icons/wallet/Deposit.png';
import exchange from './../../assets/images/icons/wallet/Exchange.png';
import transfer from './../../assets/images/icons/wallet/Transfer.png';
import withDraw from './../../assets/images/icons/wallet/Withdrawal.png';
import UploadMultipleImage from './UploadImage/UploadMultipleImage';
import { toast } from "react-toastify";
import service from '../../../service/Service';


const WalletInfo = ({ toggleVisibility }) => {

  const [isBalanceVisible, setIsBalanceVisible] = useState(false);
  const [showView, setShowView] = useState(false);
  const [avlbUsdt, setAvlbUsdt] = useState(0)

  const openView = () => setShowView(true);
  const closeView = () => setShowView(false);
  const handleToggleVisible = () => {
    setIsBalanceVisible(!isBalanceVisible);
    toggleVisibility()
  };

  useEffect(() => {
    getWalletByCoin()
  }, []);

  const getWalletByCoin = async () => {
    try {
      let res = await service.getWalletByCoin('usdt')
      setAvlbUsdt(res.data.data[0].quantity)
    } catch (err) { }
  }

  const abc = () => {
    window.open("/profile/verify-user")
  }

  const buyCrypto = async (type) => {
    let res = await service.userMe()
    let verify = res.data.data.verify
    if (verify === null) {
      openView()
    } else if (verify.isVerified) {
      window.location.href = `/action/${type}`
    } else {
      openView()
    }
  }

  const handleHistoryTrade = () => {
    window.location.href = '/profile/trade-history'
  }

  return (

    <div className="container">
      <div className="wallet-container">
        <div className="sub-title">
          <p>Estimated balance</p>
          <img
            src={isBalanceVisible ? eyeOpen : eyeClosed}
            alt={isBalanceVisible ? 'Hide' : 'Show'}
            className="eye-icon"
            onClick={handleToggleVisible}
          />
        </div>
        <div className="wallet-text">{isBalanceVisible ? <p>{avlbUsdt} USDT</p> : '******'}</div>
        <div className="button-grid">
          <button className="wallet-button" onClick={() => buyCrypto('deposit')}>
            <div className='button-content'>
              <img src={deposit} alt="Deposit" className="button-image" />
              <div className="button-title"> Deposit </div>
            </div>
          </button>
          <button className="wallet-button">
            <div className='button-content'>
              <img src={transfer} alt="Transfer" className="button-image" />
              <div className="button-title"> Transfer</div>
            </div>

          </button>
          <button className="wallet-button" onClick={handleHistoryTrade}>
            <div className='button-content'>
              <img src={exchange} alt="Exchange" className="button-image" />
              <div className="button-title"> Trading history</div>
            </div>
          </button>
          <button className="wallet-button" onClick={() => buyCrypto('withdraw')}>
            <div className='button-content'>
              <img src={withDraw} alt="Withdraw" className="button-image" />
              <div className="button-title"> Withdraw</div>
            </div>
          </button>
        </div>
      </div>
      <div>

        {showView && <UploadMultipleImage close={closeView} />}
      </div>
    </div>
  );
};

export default WalletInfo;
