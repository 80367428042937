export const aboutContent = {
    firstTitle: "Embrace the Future with Passionate Developers Who Believe in the Power of Artificial Intelligence. Get to Know Us Better!",
    firstDesc: "We’re in the business of setting up time for great things. So that’s why our values set us up for success and excellence.",
    secondTitle: "Our Team",
    secondDesc: "HyperGPT, blending over 20 years of software development expertise with a deep understanding of blockchain and web3 dynamics, has crafted an impeccable team, poised to leave a lasting impact.",
    thirdTitle: "Minds. Talent. Knowledge. Sharing. Integrity. Benevolence. Experience. Potential. Strength. Wisdom. Vision. Future. Network. Ideas.",
    thirdDesc: "",
    fourthTitle: "Would you like to join Hyper's journey?",
    fourthDesc: "",
    fifthTitle: "Our Advisors",
    fifthDesc: "HyperGPT thrives with an extraordinary advisory board, composed of brilliant minds who are true masters in their fields. They passionately accompany us on a grand voyage, as we navigate uncharted territories and forge a path towards an enduring future.",
    sixthTitle: "NETWORK. EXPERIENCE. KEY IDEAS. CREATIVE. GLOBAL. BACKUP.  FORCE. UNITY, PARTNERSHIP. SOLIDARITY. ALLIANCE. TEAMWORK. FUSION. SYNERGY. HARMONIZATION..",
};