import React, { useState, useEffect } from 'react';
import './OpenOrder.css';
import ArrowDown from '../../../assets/images/icons/ArrowDown.png'
import EditPriceView from './EditPriceView';
import service from '../../../../service/Service';
import { capitalizeFirstLetter, dateToString } from '../../../common/Common';
import { toast } from 'react-toastify';

const OpenOrder = () => {
  const [sideFilter, setSideFilter] = useState('All');
  const [openOrders, setOpenOrders] = useState([])
  const [allOpenOrders, setAllOpenOrders] = useState([])
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [price, setPrice] = useState(0);
  const [amount, setAmount] = useState(0);
  const [isShowTrade, setIsShowTrade] = useState(false)
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(0);
  const [filteredData, setFilteredData] = useState([])

  const [editPosition, setEditPosition] = useState({
    x: 0,
    y: 0
  })

  useEffect(() => {
    getAllOpenOrders()
  }, [])

  useEffect(() => {
    getOpenOrderPerPage(allOpenOrders)

  }, [currentPage, sideFilter, allOpenOrders])

  const getOpenOrderPerPage = (data) => {
    let allOrders = data
      .filter(row =>
        (sideFilter === 'All' || row.type === sideFilter)
      )
    setTotal(allOrders.length)
    let result = allOrders
      .slice((currentPage - 1) * limit, currentPage * limit)
    setFilteredData(result)
  }

  const getAllOpenOrders = async () => {
    try {
      let res = await service.getLimitMe(Number.MAX_SAFE_INTEGER, 1)
      setAllOpenOrders(res.data.data)
      getOpenOrderPerPage(res.data.data)
      setIsShowTrade(true)
    } catch (error) {
      toast.error(`${error}`, {
        position: 'bottom-right'
      })
    }
  }

  const handleEditClick = (event, index) => {
    setIsModalOpen(true);
    const buttonPositionX = event.pageX;
    const buttonPositionY = event.pageY;
    const top = buttonPositionX - 150
    const left = buttonPositionY - 60
    setEditPosition({ x: left, y: top })
  }

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleSave = (newPrice, newAmount) => {
    setPrice(newPrice);
    setAmount(newAmount);
  };

  const handleSideSelect = (event) => {
    setSideFilter(event.target.getAttribute("data-value"));
    setCurrentPage(1)
  };

  const deleteOrder = async (id) => {
    // swal({
    //   title: "Are you sure?",
    //   text: "Once deleted, you will not be able to recover this imaginary file!",
    //   icon: "warning",
    //   buttons: true,
    //   dangerMode: true,
    // })
    //   .then(async (willDelete) => {
    //     if (willDelete) {
    //       try {
    //         await service.deleteLimitWithId(id)
    //         swal("Poof! Your order has been deleted!", {
    //           icon: "success",
    //         });
    //       } catch (error) {
    //         swal(`${error}`, {
    //           icon: "error",
    //         });
    //       }
    //     } else { }
    //   });

    try {
      let res = await service.deleteLimitWithId(id)
      toast.success(`${res.data.data}`, {
        position: 'bottom-right'
      })
      setAllOpenOrders(allOpenOrders => allOpenOrders.filter(item => item.id !== id))
      if (filteredData.length === 1) {
        setCurrentPage(1)
      }
    } catch (error) {
      toast.error(`${error}`, {
        position: 'bottom-right'
      })
    }
  }

  const handleChangePage = (page) => {
    setCurrentPage(page);
  };

  return (
    <>
      {isShowTrade && (
        <div className="container_open_order">
          <div className="container-table">
            <table className="data-table-spot-open-order">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Pair</th>
                  <th>
                    <div className="dropdown">
                      <button className="dropbtn">Side <img src={ArrowDown} width={16} height={16} /></button>
                      <div className="dropdown-content">
                        <span className={`${sideFilter === 'All' ? 'selected-dropdown' : ''}`} data-value="All" onClick={handleSideSelect}>All</span>
                        <span className={`${sideFilter === 'buy' ? 'selected-dropdown' : ''}`} data-value="buy" onClick={handleSideSelect}>Buy</span>
                        <span className={`${sideFilter === 'sell' ? 'selected-dropdown' : ''}`} data-value="sell" onClick={handleSideSelect}>Sell</span>
                      </div>
                    </div>
                  </th>
                  <th>Expect Price</th>
                  <th>Lost Stop Price</th>
                  <th>Quanity</th>
                  <th>Total</th>
                  <th>Cancel</th>
                </tr>
              </thead>
              <tbody>
                {filteredData.map((row, index) => (
                  <tr key={index}>
                    <td>{dateToString(row.updatedAt)}</td>
                    <td>{row.coinName}</td>
                    <td style={{color: row.type === 'sell' ? '#f2465c' : '#2c9069', fontWeight: '700'}} >{capitalizeFirstLetter(row.type)}</td>
                    <td>{row.expectPrice}</td>
                    {/* <button><img className='price' src={Edit} width={12} height={12} onClick={(event) => handleEditClick(event, index)} /> </button> */}
                    <td>{row.lossStopPrice}</td>
                    <td>{row.quantity}</td>
                    <td>{row.totalPay}</td>
                    {/* <td>
                      <button onClick={() => deleteOrder(row.id)}><img src={Trash} width={18} height={18} /></button>
                    </td> */}
                    <td>
                      <button onClick={() => deleteOrder(row.id)}>Cancel</button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {total > 10 && (
            <div className="pagination">
              {Array.from({ length: Math.ceil(total / limit) }, (_, index) => (
                <button
                  key={index}
                  onClick={() => handleChangePage(index + 1)}
                  className={currentPage === index + 1 ? 'active' : ''}
                >
                  {index + 1}
                </button>
              ))}
            </div>
          )}

          {/* don't use */}
          <div style={{ backgroundColor: '#f0f0f0', top: editPosition.x, left: editPosition.y, position: 'absolute' }}>
            <EditPriceView
              isOpen={isModalOpen}
              onClose={handleCloseModal}
              onSave={handleSave}
              price={price}
              amount={amount}
            />
          </div>
        </div>
      )}
    </>

  );
}

export default OpenOrder;
