// import {
//     HyperFlare,
//     HyperNova,
//     HyperSonic,
//     HyperVolt,
//     HyperVoter,
//     StepFirst,
//     StepSecond,
//     StepThird,
//     StepFourth,
// } from "../../assets";
import logo from "../../../logo.svg"
import React  from 'react';
export const AmbassadorProgram = {
    topContent: {
        title: "**Welcome to the HyperGPT Ambassador Program**",
        desc: "At HyperGPT, we believe in the power of community support for growth and success. That's why we're thrilled to introduce the HyperGPT Ambassador Program. This initiative is designed to bring together enthusiastic and talented individuals who are passionate about promoting and expanding our project. By joining our Ambassador Program, you'll have the opportunity to make a significant impact on the HyperGPT community and contribute to the development of our project.",
    },
    stepContent: [{
            id: 1,
            title: ( <
                div >
                Vote
                for the future of
                <
                br / >
                HyperGPT!
                <
                /div>
            ),
            desc: "Have a say in the future of HyperGPT by holding the HGPT token. Remember, your decision will be the key to success!",
        },
        {
            id: 2,
            title: ( <
                div >
                EARN $HGPT BY ADDING <
                br / >
                AND VALIDATING AI APPs!
                <
                /div>
            ),
            desc: "By holding 100+ HGPT tokens, gain the right to add and validate products in the HyperStore. So earn on monthly rewards!",
        },
        {
            id: 3,
            title: ( <
                div >
                Earn $HGPT by commentıng and <
                br / >
                comment validating!
                <
                /div>
            ),
            desc: "By holding 100+ HGPT tokens, you get the right to comment on products and validate comments on the HyperStore. So earn more from monthly rewards!",
        },
        {
            id: 4,
            title: ( <
                div >
                Take your place in the DAO <
                br / >
                channel on Discord!
                <
                /div>
            ),
            desc: "If you have completed all the requirements to become a DAO member, you can take your place in  Discord DAO channel and be informed about all the developments.",
        },
    ],
    bottomContent: {
        lightTitle: "Make your life easier with HyperStore!",
        title: "**Simplify Your AI Integration** Process with HyperStore",
        desc: "HyperGPT platform is designed to be user-friendly and easily accessible, allowing you to quickly and easily find the AI applications that are best suited for your needs. With its powerful search capabilities, you can filter results by price, functionality and popularity, making it easy to find the perfect solution for your  project. \n\n In addition to offering access to a wide range of AI applications, HyperGPT platform also provides a range of value-added services. HyperGPT platform allows you to easily integrate iframe codes and AI solutions into your projects, making it easy to get up and running quickly.",
    },
    infoWrapper: {
        firstTitle: "AMBASSADOR ROLES",
        firstDesc: "You can use the following information to get a better understanding of the roles within the HyperGPT Ambassador Program:",
        secondTitle: "AMBASSADOR REQUIREMENTS",
        secondDesc: "Certainly, here are the detailed requirements for joining the HyperGPT Ambassador Program:",
        thirdTitle: "AMBASSADOR BENEFITS",
        thirdDesc: ["test"],
    },
};

export const becomeDatasAmbassador = [{
        id: logo,
        title: "Claim one of the HyperDAO role on Discord",
        desc: `1.1. Hold minimum 100 $HGPT Token [ Buy on MEXC | Bitmart | Pancakeswap ] 
    1.2. Verify on CollabLand)`,
        btnText: "",
        link: "",
    },
    {
        id: logo,
        title: "Update your names on TG, Discord and Twitter",
        desc: "Example: Username | HyperGPT",
        btnText: "",
        link: "",
    },
    {
        id: logo,
        title: "Connect your Discord to HyperStore",
        desc: "Fill out the form completely to take your place among HyperGPT Ambassadors.",
        btnText: "Click to fill out the form",
        link: "https://forms.gle/VJszyCNd5QUf69uA7",
    },
    // {
    //   id: StepFourth,
    //   title: "CONNECT YOUR DISCORD TO HYPERSTORE",
    //   desc: "Visit HyperStore and link your Discord account. So you can add, validate, and contribute comments on the HyperStore. You're in control now, what are you waiting for!",
    //   btnText: "Visit HyperStore",
    //   link: "/hyperstore",
    // },
];

export const roleDatasAmbassador = [{
        id: 1,
        title: "Analysts",
        listDesc: [
            "Dive deep into price charts for HyperGPT and provide valuable insights into the project's economic outlook.",
        ],
        icon: "",
    },
    {
        id: 2,
        title: "Original Content Creators",
        listDesc: [
            "Craft original blog posts, articles, or other compelling content to promote HyperGPT and enrich our community.",
        ],
        icon: "",
    },
    {
        id: 3,
        title: "MEME Creators",
        listDesc: [
            "Create fun and engaging memes that can help HyperGPT go viral and capture the attention of a broader audience.",
        ],
        icon: "",
    },
    {
        id: 4,
        title: "Translator and Localizer",
        listDesc: [
            "Translate HyperGPT's content into various languages, ensuring global access and inclusivity.",
        ],
        icon: "",
    },
    {
        id: 5,
        title: "Social Media Supporters",
        listDesc: [
            "Actively engage with HyperGPT's social media content, help grow our follower base, and encourage meaningful interactions within the community.",
        ],
        icon: "",
    },
    {
        id: 6,
        title: "Agenda Setters",
        listDesc: [
            "Keep HyperGPT in the spotlight across different platforms, organize exciting community events, and highlight the project's significance.",
        ],
        icon: "",
    },
    {
        id: 7,
        title: "Local Community Managers",
        listDesc: [
            "Serve as moderators in local communities on Telegram and Discord, fostering a sense of unity and providing assistance to participants.",
        ],
        icon: "",
    },
];