import './TradeView.css'
import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import BuyMarketView from './BuyMarketView';
import BuyLimitView from './BuyLimitView';
import SellLimitView from './SellLimitView';
import SellMarketView from './SellMarketView';
import BuyStopLimitView from './BuyStopLimitView';
import SellStopLimitView from './SellStopLimitView.';
import BuyStopMarketView from './BuyStopMarketView';
import SellStopMarketView from './SellStopMarketView';

const TradeView = () => {

    const [activeButton, setActiveButton] = useState(1); // 1 is buy, 2 is sell
    const [typeTrade, setTypeTrade] = React.useState('1'); // 1 is market, 2 is limit

    const handleChange = (event, newValue) => {
        setTypeTrade(newValue);
    };

    const handleClick = (buttonNumber) => {
        setActiveButton(buttonNumber);
    };

    return (
        <div className='container'>
            <div className="button-group-container">
                <button
                    className={`button button-1 ${activeButton === 1 ? 'active' : ''}`}
                    onClick={() => handleClick(1)}
                >
                    Buy
                </button>
                <button
                    className={`button button-2 ${activeButton === 2 ? 'active' : ''}`}
                    onClick={() => handleClick(2)}
                >
                    Sell
                </button>
            </div>

            <div>


                <Box sx={{ width: '100%', typography: 'body1' }}>
                    <TabContext value={typeTrade} >
                        <Box sx={{
                            borderBottom: 1, borderColor: '', position: "relative", "& .MuiTabs-indicator": {
                                position: "absolute",
                                bottom: 15,
                                margrinTop: '15px',
                                width: "100%",
                                backgroundColor: 'white'
                            }
                        }}>
                            <TabList
                                onChange={handleChange}
                                aria-label="lab API tabs example"
                                sx={{
                                    "& .MuiTab-root": {
                                        minHeight: "auto", maxHeight: '35px', textTransform: "none", color: "#808080",
                                        '&.Mui-selected': {
                                            color: 'white',
                                        }
                                    }
                                }}
                            >
                                <Tab
                                    label="Market"
                                    value="1"
                                    sx={{ minHeight: "auto", maxHeight: '35px' }}
                                />
                                {/* <Tab
                                    label="Stop Market"
                                    value="2"
                                    sx={{ minHeight: "auto", maxHeight: '35px' }}
                                /> */}
                                <Tab
                                    label="Limit"
                                    value="3"
                                    sx={{ minHeight: "auto", maxHeight: '35px', color: 'white', fontWeight: '700' }}
                                />
                                {/* <Tab
                                    label="Stop Limit"
                                    value="4"
                                    sx={{ minHeight: "auto", maxHeight: '35px' }}
                                /> */}
                            </TabList>
                            <div className='tabBorder' />
                        </Box>

                        <TabPanel value="1">
                            {activeButton === 1 ? (
                                <BuyMarketView />
                            ) : (
                                <SellMarketView />
                            )}
                        </TabPanel>
                        <TabPanel value="2">
                            // chưa
                            {activeButton === 1 ? (
                                <BuyStopLimitView value='Market' />
                            ) : (
                                <SellStopLimitView value='Market' />
                            )}
                        </TabPanel>
                        <TabPanel value="3">
                            {activeButton === 1 ? (
                                <BuyLimitView />
                            ) : (
                                <SellLimitView />
                            )}
                        </TabPanel>
                        <TabPanel value="4">
                            {activeButton === 1 ? (
                                <BuyStopLimitView value='Limit' />
                            ) : (
                                <SellStopLimitView value='Limit' />
                            )}
                        </TabPanel>
                        {/* <TabPanel value="3" style={{ color: 'blue' }}>Item Three</TabPanel> */}
                    </TabContext>
                </Box>
            </div>
        </div>
    );
}

export default TradeView;